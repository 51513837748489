<template>
  <div class="chart-container-box ml55 pr" @click="$emit('fn', 'co')">
    <span class="pa top7 left-60 tc cf cp" style="z-index: 999">
      <div>CO</div>
      (ppm)
    </span>
    <div ref="CoChart" class="chart w299 h65"></div>
    <div class="pa right8 cf tc top8">{{ value && value.toFixed(2) }}</div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "C0Chart",
  data() {
    return {
      chart: null,
      value: 0,

      option: {
        grid: {
          top: "40%",
          height: "40%",
          borderWidth: 1,
          borderColor: "#294598",
        },
        backgroundColor: "#02173dc4",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          show: false,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          show: false,
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "CO",
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            data: [],
          },
        ],
      },
    };
  },
  props: {
    airData: {
      type: String | Number,
      default: 0,
    },
  },
  mounted() {
    this.initChart();
    this.time = setInterval(() => {
      if (this.airData >= 0) {
        this.updateData(this.airData);
      }
    }, 1000);
  },
  methods: {
    initChart() {
      console.log(echarts, "echarts");
      this.chart = echarts.init(this.$refs.CoChart);
      this.chart.setOption(this.option);
    },
    updateData(value) {
      const now = new Date().getTime();
      this.value = value;
      this.option.series[0].data.push([now, value]);
      this.chart.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-container-box {
  width: 300px;
  border: 1px solid #3bc1e5;
}
</style>
