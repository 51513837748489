export default {
    mqtt:{
        client:null
    },
    customProperty:{},
    gasDetectionObj:{},
    gasDetection:[],
    // 测试数据
    uav: {
        linecopy: [{   x: -2678764.420122798,
            y: 4607649.276733472,
            z: 3503558.1793838153,}],
        loca: {
            x: -2678764.420122798,
            y: 4607649.276733472,
            z: 3503558.1793838153,
        },
    },
    line:[
        {
            "x": -2678781.728398804,
            "y": 4607699.369921466,
            "z": 3503589.8612064472
        },
        {
            "x": -2678781.728398804,
            "y": 4607699.369921466,
            "z": 3503589.8612064472
        },
        {
            "x": -2678781.7400871753,
            "y": 4607699.371518216,
            "z": 3503589.8792975643
        },
        {
            "x": -2678781.7400871753,
            "y": 4607699.371518216,
            "z": 3503589.8792975643
        },
        {
            "x": -2678781.746244418,
            "y": 4607699.345092925,
            "z": 3503589.8929583393
        },
        {
            "x": -2678781.746244418,
            "y": 4607699.345092925,
            "z": 3503589.8929583393
        },
        {
            "x": -2678781.746244418,
            "y": 4607699.345092925,
            "z": 3503589.8929583393
        },
        {
            "x": -2678781.7454044423,
            "y": 4607699.343648106,
            "z": 3503589.8918597316
        },
        {
            "x": -2678781.7454044423,
            "y": 4607699.343648106,
            "z": 3503589.8918597316
        },
        {
            "x": -2678781.7446357533,
            "y": 4607699.360834004,
            "z": 3503589.8880505147
        },
        {
            "x": -2678781.7446357533,
            "y": 4607699.360834004,
            "z": 3503589.8880505147
        },
        {
            "x": -2678781.738960683,
            "y": 4607699.369580568,
            "z": 3503589.8645022693
        },
        {
            "x": -2678781.738960683,
            "y": 4607699.369580568,
            "z": 3503589.8645022693
        },
        {
            "x": -2678781.724270213,
            "y": 4607699.381328088,
            "z": 3503589.853002799
        },
        {
            "x": -2678781.724270213,
            "y": 4607699.381328088,
            "z": 3503589.853002799
        },
        {
            "x": -2678781.715521756,
            "y": 4607699.384788205,
            "z": 3503589.8387568095
        },
        {
            "x": -2678781.7060772665,
            "y": 4607699.350034898,
            "z": 3503589.842530118
        },
        {
            "x": -2678781.7060772665,
            "y": 4607699.350034898,
            "z": 3503589.842530118
        },
        {
            "x": -2678781.6967662475,
            "y": 4607699.31551117,
            "z": 3503589.833156044
        },
        {
            "x": -2678781.6967662475,
            "y": 4607699.31551117,
            "z": 3503589.833156044
        },
        {
            "x": -2678781.69479978,
            "y": 4607699.312128701,
            "z": 3503589.8172621424
        },
        {
            "x": -2678781.69479978,
            "y": 4607699.312128701,
            "z": 3503589.8172621424
        },
        {
            "x": -2678781.6957732257,
            "y": 4607699.3138031,
            "z": 3503589.805213368
        },
        {
            "x": -2678781.6957732257,
            "y": 4607699.3138031,
            "z": 3503589.805213368
        },
        {
            "x": -2678781.709121974,
            "y": 4607699.318255819,
            "z": 3503589.78551025
        },
        {
            "x": -2678781.709121974,
            "y": 4607699.318255819,
            "z": 3503589.78551025
        },
        {
            "x": -2678781.7096132487,
            "y": 4607699.337608946,
            "z": 3503589.7833489445
        },
        {
            "x": -2678781.717931247,
            "y": 4607699.370424605,
            "z": 3503589.7647803435
        },
        {
            "x": -2678781.717931247,
            "y": 4607699.370424605,
            "z": 3503589.7647803435
        },
        {
            "x": -2678781.7218341357,
            "y": 4607699.414154056,
            "z": 3503589.7243114077
        },
        {
            "x": -2678781.7218341357,
            "y": 4607699.414154056,
            "z": 3503589.7243114077
        },
        {
            "x": -2678781.7142016985,
            "y": 4607699.456550007,
            "z": 3503589.6925954237
        },
        {
            "x": -2678781.7225196925,
            "y": 4607699.489365666,
            "z": 3503589.6740268213
        },
        {
            "x": -2678781.7225196925,
            "y": 4607699.489365666,
            "z": 3503589.6740268213
        },
        {
            "x": -2678781.7386748865,
            "y": 4607699.498645672,
            "z": 3503589.671316211
        },
        {
            "x": -2678781.7386748865,
            "y": 4607699.498645672,
            "z": 3503589.671316211
        },
        {
            "x": -2678781.7529453696,
            "y": 4607699.486175741,
            "z": 3503589.6822663783
        },
        {
            "x": -2678781.7529453696,
            "y": 4607699.486175741,
            "z": 3503589.6822663783
        },
        {
            "x": -2678781.7842214922,
            "y": 4607699.44743245,
            "z": 3503589.72386983
        },
        {
            "x": -2678781.7842214922,
            "y": 4607699.44743245,
            "z": 3503589.72386983
        },
        {
            "x": -2678781.80486445,
            "y": 4607699.39039934,
            "z": 3503589.7648880705
        },
        {
            "x": -2678781.80486445,
            "y": 4607699.39039934,
            "z": 3503589.7648880705
        },
        {
            "x": -2678781.814874238,
            "y": 4607699.31507641,
            "z": 3503589.8053210964
        },
        {
            "x": -2678781.814874238,
            "y": 4607699.31507641,
            "z": 3503589.8053210964
        },
        {
            "x": -2678781.796280878,
            "y": 4607699.283094481,
            "z": 3503589.8342905603
        },
        {
            "x": -2678781.796280878,
            "y": 4607699.283094481,
            "z": 3503589.8342905603
        },
        {
            "x": -2678781.7562863445,
            "y": 4607699.288333374,
            "z": 3503589.850697856
        },
        {
            "x": -2678781.7562863445,
            "y": 4607699.288333374,
            "z": 3503589.850697856
        },
        {
            "x": -2678781.7227872857,
            "y": 4607699.2862368515,
            "z": 3503589.851760554
        },
        {
            "x": -2678781.698661428,
            "y": 4607699.30026292,
            "z": 3503589.851760553
        },
        {
            "x": -2678781.698661428,
            "y": 4607699.30026292,
            "z": 3503589.851760553
        },
        {
            "x": -2678781.6849639798,
            "y": 4607699.313718509,
            "z": 3503589.868203759
        },
        {
            "x": -2678781.6849639798,
            "y": 4607699.313718509,
            "z": 3503589.868203759
        },
        {
            "x": -2678781.6801484586,
            "y": 4607699.323943561,
            "z": 3503589.8857455715
        },
        {
            "x": -2678781.68784171,
            "y": 4607699.337176513,
            "z": 3503589.882485658
        },
        {
            "x": -2678781.68784171,
            "y": 4607699.337176513,
            "z": 3503589.882485658
        },
        {
            "x": -2678781.700217012,
            "y": 4607699.3399548335,
            "z": 3503589.874831314
        },
        {
            "x": -2678781.700217012,
            "y": 4607699.3399548335,
            "z": 3503589.874831314
        },
        {
            "x": -2678781.716505674,
            "y": 4607699.349464419,
            "z": 3503589.8589733234
        },
        {
            "x": -2678781.719999048,
            "y": 4607699.355473275,
            "z": 3503589.850220371
        },
        {
            "x": -2678781.719999048,
            "y": 4607699.355473275,
            "z": 3503589.850220371
        },
        {
            "x": -2678781.730078755,
            "y": 4607699.372811104,
            "z": 3503589.8634036626
        },
        {
            "x": -2678781.730078755,
            "y": 4607699.372811104,
            "z": 3503589.8634036626
        },
        {
            "x": -2678781.7223533653,
            "y": 4607699.341014773,
            "z": 3503589.949357085
        },
        {
            "x": -2678781.6829828527,
            "y": 4607699.236278432,
            "z": 3503590.1299450607
        },
        {
            "x": -2678781.6829828527,
            "y": 4607699.236278432,
            "z": 3503590.1299450607
        },
        {
            "x": -2678781.622529089,
            "y": 4607699.058261175,
            "z": 3503590.408463406
        },
        {
            "x": -2678781.622529089,
            "y": 4607699.058261175,
            "z": 3503590.408463406
        },
        {
            "x": -2678781.55732198,
            "y": 4607698.8720678175,
            "z": 3503590.69408678
        },
        {
            "x": -2678781.488539731,
            "y": 4607698.698233064,
            "z": 3503590.9589084187
        },
        {
            "x": -2678781.488539731,
            "y": 4607698.698233064,
            "z": 3503590.9589084187
        },
        {
            "x": -2678781.404933535,
            "y": 4607698.535916241,
            "z": 3503591.2253779597
        },
        {
            "x": -2678781.404933535,
            "y": 4607698.535916241,
            "z": 3503591.2253779597
        },
        {
            "x": -2678781.325107221,
            "y": 4607698.38010109,
            "z": 3503591.496791228
        },
        {
            "x": -2678781.325107221,
            "y": 4607698.38010109,
            "z": 3503591.496791228
        },
        {
            "x": -2678781.2348007737,
            "y": 4607698.206259351,
            "z": 3503591.794463335
        },
        {
            "x": -2678781.2348007737,
            "y": 4607698.206259351,
            "z": 3503591.794463335
        },
        {
            "x": -2678781.1325390055,
            "y": 4607698.030361684,
            "z": 3503592.1003390746
        },
        {
            "x": -2678781.1325390055,
            "y": 4607698.030361684,
            "z": 3503592.1003390746
        },
        {
            "x": -2678781.0408391044,
            "y": 4607697.854123101,
            "z": 3503592.4095106293
        },
        {
            "x": -2678780.9338952666,
            "y": 4607697.688680033,
            "z": 3503592.71978078
        },
        {
            "x": -2678780.9338952666,
            "y": 4607697.688680033,
            "z": 3503592.71978078
        },
        {
            "x": -2678780.8304447997,
            "y": 4607697.52924581,
            "z": 3503593.0212979726
        },
        {
            "x": -2678780.8304447997,
            "y": 4607697.52924581,
            "z": 3503593.0212979726
        },
        {
            "x": -2678780.732516347,
            "y": 4607697.360801761,
            "z": 3503593.319519328
        },
        {
            "x": -2678780.732516347,
            "y": 4607697.360801761,
            "z": 3503593.319519328
        },
        {
            "x": -2678780.6138841254,
            "y": 4607697.193761904,
            "z": 3503593.6116983257
        },
        {
            "x": -2678780.6138841254,
            "y": 4607697.193761904,
            "z": 3503593.6116983257
        },
        {
            "x": -2678780.5103427824,
            "y": 4607697.015663274,
            "z": 3503593.8759346334
        },
        {
            "x": -2678780.5103427824,
            "y": 4607697.015663274,
            "z": 3503593.8759346334
        },
        {
            "x": -2678780.4016993977,
            "y": 4607696.847296853,
            "z": 3503594.144016061
        },
        {
            "x": -2678780.3010162143,
            "y": 4607696.692622551,
            "z": 3503594.382542814
        },
        {
            "x": -2678780.3010162143,
            "y": 4607696.692622551,
            "z": 3503594.382542814
        },
        {
            "x": -2678780.2085797433,
            "y": 4607696.552133195,
            "z": 3503594.60521158
        },
        {
            "x": -2678780.2085797433,
            "y": 4607696.552133195,
            "z": 3503594.60521158
        },
        {
            "x": -2678780.15011404,
            "y": 4607696.433059849,
            "z": 3503594.784664918
        },
        {
            "x": -2678780.15011404,
            "y": 4607696.433059849,
            "z": 3503594.784664918
        },
        {
            "x": -2678780.1042996966,
            "y": 4607696.354255852,
            "z": 3503594.9378953464
        },
        {
            "x": -2678780.1042996966,
            "y": 4607696.354255852,
            "z": 3503594.9378953464
        },
        {
            "x": -2678780.085674138,
            "y": 4607696.303710452,
            "z": 3503595.049558268
        },
        {
            "x": -2678780.085674138,
            "y": 4607696.303710452,
            "z": 3503595.049558268
        },
        {
            "x": -2678780.069004595,
            "y": 4607696.293545714,
            "z": 3503595.1448498378
        },
        {
            "x": -2678780.069004595,
            "y": 4607696.293545714,
            "z": 3503595.1448498378
        },
        {
            "x": -2678780.1204960644,
            "y": 4607696.437639042,
            "z": 3503595.283716123
        },
        {
            "x": -2678780.1204960644,
            "y": 4607696.437639042,
            "z": 3503595.283716123
        },
        {
            "x": -2678780.258514072,
            "y": 4607696.767580486,
            "z": 3503595.543465361
        },
        {
            "x": -2678780.258514072,
            "y": 4607696.767580486,
            "z": 3503595.543465361
        },
        {
            "x": -2678780.50024944,
            "y": 4607697.275923341,
            "z": 3503595.8989013955
        },
        {
            "x": -2678780.8547280156,
            "y": 4607697.922668458,
            "z": 3503596.370240706
        },
        {
            "x": -2678780.8547280156,
            "y": 4607697.922668458,
            "z": 3503596.370240706
        },
        {
            "x": -2678781.322923262,
            "y": 4607698.7094902415,
            "z": 3503596.9454345163
        },
        {
            "x": -2678781.322923262,
            "y": 4607698.7094902415,
            "z": 3503596.9454345163
        },
        {
            "x": -2678781.8809867436,
            "y": 4607699.613875721,
            "z": 3503597.6304533244
        },
        {
            "x": -2678782.448862977,
            "y": 4607700.5351398215,
            "z": 3503598.3549502213
        },
        {
            "x": -2678782.9932290115,
            "y": 4607701.452980864,
            "z": 3503599.0564122456
        },
        {
            "x": -2678782.9932290115,
            "y": 4607701.452980864,
            "z": 3503599.0564122456
        },
        {
            "x": -2678783.536182065,
            "y": 4607702.368391464,
            "z": 3503599.729382264
        },
        {
            "x": -2678783.536182065,
            "y": 4607702.368391464,
            "z": 3503599.729382264
        },
        {
            "x": -2678784.077455169,
            "y": 4607703.280912432,
            "z": 3503600.400155056
        },
        {
            "x": -2678784.077455169,
            "y": 4607703.280912432,
            "z": 3503600.400155056
        },
        {
            "x": -2678784.6160748755,
            "y": 4607704.188869346,
            "z": 3503601.080779411
        },
        {
            "x": -2678784.6160748755,
            "y": 4607704.188869346,
            "z": 3503601.080779411
        },
        {
            "x": -2678785.148394771,
            "y": 4607705.085990125,
            "z": 3503601.753164192
        },
        {
            "x": -2678785.6789012365,
            "y": 4607705.979991665,
            "z": 3503602.436499152
        },
        {
            "x": -2678785.6789012365,
            "y": 4607705.979991665,
            "z": 3503602.436499152
        },
        {
            "x": -2678786.2231960115,
            "y": 4607706.879201963,
            "z": 3503603.1406717813
        },
        {
            "x": -2678786.2231960115,
            "y": 4607706.879201963,
            "z": 3503603.1406717813
        },
        {
            "x": -2678786.715421414,
            "y": 4607707.725865409,
            "z": 3503603.78445681
        },
        {
            "x": -2678786.715421414,
            "y": 4607707.725865409,
            "z": 3503603.78445681
        },
        {
            "x": -2678787.08116836,
            "y": 4607708.373484858,
            "z": 3503604.273337933
        },
        {
            "x": -2678787.08116836,
            "y": 4607708.373484858,
            "z": 3503604.273337933
        },
        {
            "x": -2678787.367384644,
            "y": 4607708.884305793,
            "z": 3503604.631556361
        },
        {
            "x": -2678787.367384644,
            "y": 4607708.884305793,
            "z": 3503604.631556361
        },
        {
            "x": -2678787.589567128,
            "y": 4607709.303492084,
            "z": 3503604.9298646776
        },
        {
            "x": -2678787.7698325883,
            "y": 4607709.63206992,
            "z": 3503605.2027972722
        },
        {
            "x": -2678787.7698325883,
            "y": 4607709.63206992,
            "z": 3503605.2027972722
        },
        {
            "x": -2678787.9021572624,
            "y": 4607709.896694257,
            "z": 3503605.4235459305
        },
        {
            "x": -2678787.9021572624,
            "y": 4607709.896694257,
            "z": 3503605.4235459305
        },
        {
            "x": -2678788.026893561,
            "y": 4607710.111249755,
            "z": 3503605.586689407
        },
        {
            "x": -2678788.115448723,
            "y": 4607710.282079213,
            "z": 3503605.7130294726
        },
        {
            "x": -2678788.115448723,
            "y": 4607710.282079213,
            "z": 3503605.7130294726
        },
        {
            "x": -2678788.206290449,
            "y": 4607710.401317296,
            "z": 3503605.8108056975
        },
        {
            "x": -2678788.206290449,
            "y": 4607710.401317296,
            "z": 3503605.8108056975
        },
        {
            "x": -2678788.206290449,
            "y": 4607710.401317296,
            "z": 3503605.8108056975
        },
        {
            "x": -2678788.277259273,
            "y": 4607710.486372542,
            "z": 3503605.8959120144
        },
        {
            "x": -2678788.333815033,
            "y": 4607710.546636273,
            "z": 3503605.9754893845
        },
        {
            "x": -2678788.333815033,
            "y": 4607710.546636273,
            "z": 3503605.9754893845
        },
        {
            "x": -2678788.3668800876,
            "y": 4607710.603510569,
            "z": 3503606.0720233982
        },
        {
            "x": -2678788.3668800876,
            "y": 4607710.603510569,
            "z": 3503606.0720233982
        },
        {
            "x": -2678788.3736389396,
            "y": 4607710.615136286,
            "z": 3503606.160795346
        },
        {
            "x": -2678788.3736389396,
            "y": 4607710.615136286,
            "z": 3503606.160795346
        },
        {
            "x": -2678788.386811566,
            "y": 4607710.63779416,
            "z": 3503606.2046679165
        },
        {
            "x": -2678788.386811566,
            "y": 4607710.63779416,
            "z": 3503606.2046679165
        },
        {
            "x": -2678788.3861854244,
            "y": 4607710.692241588,
            "z": 3503606.19543744
        },
        {
            "x": -2678788.3861854244,
            "y": 4607710.692241588,
            "z": 3503606.19543744
        },
        {
            "x": -2678788.3528896733,
            "y": 4607710.783035629,
            "z": 3503606.1561029092
        },
        {
            "x": -2678788.3528896733,
            "y": 4607710.783035629,
            "z": 3503606.1561029092
        },
        {
            "x": -2678788.270850816,
            "y": 4607710.882528721,
            "z": 3503606.1189296963
        },
        {
            "x": -2678788.270850816,
            "y": 4607710.882528721,
            "z": 3503606.1189296963
        },
        {
            "x": -2678788.2018225053,
            "y": 4607710.967384603,
            "z": 3503606.091058761
        },
        {
            "x": -2678788.2018225053,
            "y": 4607710.967384603,
            "z": 3503606.091058761
        },
        {
            "x": -2678788.1528732465,
            "y": 4607711.031253486,
            "z": 3503606.0845389063
        },
        {
            "x": -2678788.156242213,
            "y": 4607711.074064649,
            "z": 3503606.0966595076
        },
        {
            "x": -2678788.156242213,
            "y": 4607711.074064649,
            "z": 3503606.0966595076
        },
        {
            "x": -2678788.1866965354,
            "y": 4607711.107940158,
            "z": 3503606.1526167793
        },
        {
            "x": -2678788.1866965354,
            "y": 4607711.107940158,
            "z": 3503606.1526167793
        },
        {
            "x": -2678788.213657471,
            "y": 4607711.135806787,
            "z": 3503606.2173270257
        },
        {
            "x": -2678788.256334116,
            "y": 4607711.172197434,
            "z": 3503606.2387858066
        },
        {
            "x": -2678788.256334116,
            "y": 4607711.172197434,
            "z": 3503606.2387858066
        },
        {
            "x": -2678788.361698823,
            "y": 4607711.1868590815,
            "z": 3503606.175353667
        },
        {
            "x": -2678788.361698823,
            "y": 4607711.1868590815,
            "z": 3503606.175353667
        },
        {
            "x": -2678788.361698823,
            "y": 4607711.1868590815,
            "z": 3503606.175353667
        },
        {
            "x": -2678788.561017298,
            "y": 4607711.178046573,
            "z": 3503606.0363687864
        },
        {
            "x": -2678788.9056343045,
            "y": 4607711.12302774,
            "z": 3503605.852520419
        },
        {
            "x": -2678788.9056343045,
            "y": 4607711.12302774,
            "z": 3503605.852520419
        },
        {
            "x": -2678789.3936651233,
            "y": 4607711.000052553,
            "z": 3503605.6374693806
        },
        {
            "x": -2678789.965055577,
            "y": 4607710.835380467,
            "z": 3503605.42626347
        },
        {
            "x": -2678790.546301434,
            "y": 4607710.669152175,
            "z": 3503605.2041073577
        },
        {
            "x": -2678790.546301434,
            "y": 4607710.669152175,
            "z": 3503605.2041073577
        },
        {
            "x": -2678791.1316136736,
            "y": 4607710.509918288,
            "z": 3503605.000591696
        },
        {
            "x": -2678791.1316136736,
            "y": 4607710.509918288,
            "z": 3503605.000591696
        },
        {
            "x": -2678791.738183193,
            "y": 4607710.350232088,
            "z": 3503604.790520268
        },
        {
            "x": -2678792.347130058,
            "y": 4607710.1576187555,
            "z": 3503604.589165917
        },
        {
            "x": -2678792.347130058,
            "y": 4607710.1576187555,
            "z": 3503604.589165917
        },
        {
            "x": -2678792.969345325,
            "y": 4607709.913795434,
            "z": 3503604.363092865
        },
        {
            "x": -2678792.969345325,
            "y": 4607709.913795434,
            "z": 3503604.363092865
        },
        {
            "x": -2678793.582659087,
            "y": 4607709.673168981,
            "z": 3503604.095929662
        },
        {
            "x": -2678793.582659087,
            "y": 4607709.673168981,
            "z": 3503604.095929662
        },
        {
            "x": -2678794.199824015,
            "y": 4607709.457674864,
            "z": 3503603.8309995653
        },
        {
            "x": -2678794.199824015,
            "y": 4607709.457674864,
            "z": 3503603.8309995653
        },
        {
            "x": -2678794.8185353926,
            "y": 4607709.244840687,
            "z": 3503603.5814140937
        },
        {
            "x": -2678794.8185353926,
            "y": 4607709.244840687,
            "z": 3503603.5814140937
        },
        {
            "x": -2678795.4287330294,
            "y": 4607709.017362245,
            "z": 3503603.373981483
        },
        {
            "x": -2678795.4287330294,
            "y": 4607709.017362245,
            "z": 3503603.373981483
        },
        {
            "x": -2678796.0539076403,
            "y": 4607708.778628932,
            "z": 3503603.191745083
        },
        {
            "x": -2678796.0539076403,
            "y": 4607708.778628932,
            "z": 3503603.191745083
        },
        {
            "x": -2678796.689644132,
            "y": 4607708.539554602,
            "z": 3503603.012804503
        },
        {
            "x": -2678796.689644132,
            "y": 4607708.539554602,
            "z": 3503603.012804503
        },
        {
            "x": -2678797.312790062,
            "y": 4607708.315839814,
            "z": 3503602.825110943
        },
        {
            "x": -2678797.919432049,
            "y": 4607708.100753296,
            "z": 3503602.636868074
        },
        {
            "x": -2678797.919432049,
            "y": 4607708.100753296,
            "z": 3503602.636868074
        },
        {
            "x": -2678798.49795302,
            "y": 4607707.911329114,
            "z": 3503602.427274115
        },
        {
            "x": -2678798.49795302,
            "y": 4607707.911329114,
            "z": 3503602.427274115
        },
        {
            "x": -2678798.9590191287,
            "y": 4607707.778988178,
            "z": 3503602.2779241675
        },
        {
            "x": -2678798.9590191287,
            "y": 4607707.778988178,
            "z": 3503602.2779241675
        },
        {
            "x": -2678799.1614604914,
            "y": 4607707.738530531,
            "z": 3503602.2418854134
        },
        {
            "x": -2678799.1614604914,
            "y": 4607707.738530531,
            "z": 3503602.2418854134
        },
        {
            "x": -2678799.1213427708,
            "y": 4607707.706541637,
            "z": 3503602.303705572
        },
        {
            "x": -2678799.1213427708,
            "y": 4607707.706541637,
            "z": 3503602.303705572
        },
        {
            "x": -2678799.0172379944,
            "y": 4607707.694047491,
            "z": 3503602.368785668
        },
        {
            "x": -2678799.0172379944,
            "y": 4607707.694047491,
            "z": 3503602.368785668
        },
        {
            "x": -2678798.9506792305,
            "y": 4607707.690610652,
            "z": 3503602.411451954
        },
        {
            "x": -2678798.9506792305,
            "y": 4607707.690610652,
            "z": 3503602.411451954
        },
        {
            "x": -2678798.9336126973,
            "y": 4607707.661255109,
            "z": 3503602.4157746155
        },
        {
            "x": -2678798.9336126973,
            "y": 4607707.661255109,
            "z": 3503602.4157746155
        },
        {
            "x": -2678798.9454058884,
            "y": 4607707.626015892,
            "z": 3503602.373000671
        },
        {
            "x": -2678798.960558994,
            "y": 4607707.596555944,
            "z": 3503602.3346211603
        },
        {
            "x": -2678798.960558994,
            "y": 4607707.596555944,
            "z": 3503602.3346211603
        },
        {
            "x": -2678798.883485776,
            "y": 4607707.482493113,
            "z": 3503602.217691173
        },
        {
            "x": -2678798.883485776,
            "y": 4607707.482493113,
            "z": 3503602.217691173
        },
        {
            "x": -2678798.883485776,
            "y": 4607707.482493113,
            "z": 3503602.217691173
        },
        {
            "x": -2678798.670016067,
            "y": 4607707.189343746,
            "z": 3503601.967244419
        },
        {
            "x": -2678798.3255475606,
            "y": 4607706.744900299,
            "z": 3503601.600858628
        },
        {
            "x": -2678798.3255475606,
            "y": 4607706.744900299,
            "z": 3503601.600858628
        },
        {
            "x": -2678797.9453178206,
            "y": 4607706.25745326,
            "z": 3503601.198218771
        },
        {
            "x": -2678797.9453178206,
            "y": 4607706.25745326,
            "z": 3503601.198218771
        },
        {
            "x": -2678797.589089646,
            "y": 4607705.792782354,
            "z": 3503600.8164524673
        },
        {
            "x": -2678797.589089646,
            "y": 4607705.792782354,
            "z": 3503600.8164524673
        },
        {
            "x": -2678797.2771468535,
            "y": 4607705.348760903,
            "z": 3503600.461052757
        },
        {
            "x": -2678797.2771468535,
            "y": 4607705.348760903,
            "z": 3503600.461052757
        },
        {
            "x": -2678796.976381604,
            "y": 4607704.886949373,
            "z": 3503600.085913993
        },
        {
            "x": -2678796.976381604,
            "y": 4607704.886949373,
            "z": 3503600.085913993
        },
        {
            "x": -2678796.695060192,
            "y": 4607704.421566345,
            "z": 3503599.7151696635
        },
        {
            "x": -2678796.4213607553,
            "y": 4607703.950785525,
            "z": 3503599.343876032
        },
        {
            "x": -2678796.4213607553,
            "y": 4607703.950785525,
            "z": 3503599.343876032
        },
        {
            "x": -2678796.1379394005,
            "y": 4607703.481790449,
            "z": 3503598.970385186
        },
        {
            "x": -2678796.1379394005,
            "y": 4607703.481790449,
            "z": 3503598.970385186
        },
        {
            "x": -2678795.8502468416,
            "y": 4607702.986940546,
            "z": 3503598.594111927
        },
        {
            "x": -2678795.8502468416,
            "y": 4607702.986940546,
            "z": 3503598.594111927
        },
        {
            "x": -2678795.2926255953,
            "y": 4607701.990779617,
            "z": 3503597.8437626245
        },
        {
            "x": -2678795.2926255953,
            "y": 4607701.990779617,
            "z": 3503597.8437626245
        },
        {
            "x": -2678795.0197569793,
            "y": 4607701.48441173,
            "z": 3503597.4658414526
        },
        {
            "x": -2678794.7475948595,
            "y": 4607700.979259073,
            "z": 3503597.1021662867
        },
        {
            "x": -2678794.7475948595,
            "y": 4607700.979259073,
            "z": 3503597.1021662867
        },
        {
            "x": -2678794.4886480384,
            "y": 4607700.478329534,
            "z": 3503596.7319353702
        },
        {
            "x": -2678794.4886480384,
            "y": 4607700.478329534,
            "z": 3503596.7319353702
        },
        {
            "x": -2678794.230541191,
            "y": 4607699.978844816,
            "z": 3503596.3628030624
        },
        {
            "x": -2678794.230541191,
            "y": 4607699.978844816,
            "z": 3503596.3628030624
        },
        {
            "x": -2678793.9639723995,
            "y": 4607699.483313068,
            "z": 3503595.9931214503
        },
        {
            "x": -2678793.9639723995,
            "y": 4607699.483313068,
            "z": 3503595.9931214503
        },
        {
            "x": -2678793.6904881373,
            "y": 4607698.994394337,
            "z": 3503595.638235144
        },
        {
            "x": -2678793.6904881373,
            "y": 4607698.994394337,
            "z": 3503595.638235144
        },
        {
            "x": -2678793.4120353274,
            "y": 4607698.515437433,
            "z": 3503595.2740465715
        },
        {
            "x": -2678793.118820743,
            "y": 4607698.029597366,
            "z": 3503594.901069134
        },
        {
            "x": -2678793.118820743,
            "y": 4607698.029597366,
            "z": 3503594.901069134
        },
        {
            "x": -2678792.807002323,
            "y": 4607697.548773599,
            "z": 3503594.5247958726
        },
        {
            "x": -2678792.807002323,
            "y": 4607697.548773599,
            "z": 3503594.5247958726
        },
        {
            "x": -2678792.4846220273,
            "y": 4607697.068290751,
            "z": 3503594.1452267873
        },
        {
            "x": -2678792.174903566,
            "y": 4607696.59107902,
            "z": 3503593.7717000456
        },
        {
            "x": -2678792.174903566,
            "y": 4607696.59107902,
            "z": 3503593.7717000456
        },
        {
            "x": -2678791.8812690005,
            "y": 4607696.104516538,
            "z": 3503593.398173304
        },
        {
            "x": -2678791.8812690005,
            "y": 4607696.104516538,
            "z": 3503593.398173304
        },
        {
            "x": -2678791.8812690005,
            "y": 4607696.104516538,
            "z": 3503593.398173304
        },
        {
            "x": -2678791.6016897084,
            "y": 4607695.623621988,
            "z": 3503593.0191894327
        },
        {
            "x": -2678791.3191704913,
            "y": 4607695.137670577,
            "z": 3503592.6363604357
        },
        {
            "x": -2678791.3191704913,
            "y": 4607695.137670577,
            "z": 3503592.6363604357
        },
        {
            "x": -2678791.0379112405,
            "y": 4607694.653886389,
            "z": 3503592.2551793507
        },
        {
            "x": -2678791.0379112405,
            "y": 4607694.653886389,
            "z": 3503592.2551793507
        },
        {
            "x": -2678790.740363307,
            "y": 4607694.160592641,
            "z": 3503591.889856261
        },
        {
            "x": -2678790.458837109,
            "y": 4607693.676349285,
            "z": 3503591.5349699445
        },
        {
            "x": -2678790.458837109,
            "y": 4607693.676349285,
            "z": 3503591.5349699445
        },
        {
            "x": -2678790.179473004,
            "y": 4607693.177316841,
            "z": 3503591.1723933695
        },
        {
            "x": -2678790.179473004,
            "y": 4607693.177316841,
            "z": 3503591.1723933695
        },
        {
            "x": -2678789.901440193,
            "y": 4607692.699082339,
            "z": 3503590.8087541005
        },
        {
            "x": -2678789.6064835247,
            "y": 4607692.22875376,
            "z": 3503590.444016224
        },
        {
            "x": -2678789.6064835247,
            "y": 4607692.22875376,
            "z": 3503590.444016224
        },
        {
            "x": -2678789.2953716544,
            "y": 4607691.749145205,
            "z": 3503590.081988949
        },
        {
            "x": -2678789.2953716544,
            "y": 4607691.749145205,
            "z": 3503590.081988949
        },
        {
            "x": -2678789.0012549716,
            "y": 4607691.280261438,
            "z": 3503589.718349679
        },
        {
            "x": -2678789.0012549716,
            "y": 4607691.280261438,
            "z": 3503589.718349679
        },
        {
            "x": -2678788.7271976843,
            "y": 4607690.79035706,
            "z": 3503589.3360699927
        },
        {
            "x": -2678788.7271976843,
            "y": 4607690.79035706,
            "z": 3503589.3360699927
        },
        {
            "x": -2678788.4728706987,
            "y": 4607690.297374044,
            "z": 3503588.9718814204
        },
        {
            "x": -2678788.4728706987,
            "y": 4607690.297374044,
            "z": 3503588.9718814204
        },
        {
            "x": -2678788.213933015,
            "y": 4607689.833476377,
            "z": 3503588.6093766815
        },
        {
            "x": -2678787.919334205,
            "y": 4607689.382271329,
            "z": 3503588.255624886
        },
        {
            "x": -2678787.919334205,
            "y": 4607689.382271329,
            "z": 3503588.255624886
        },
        {
            "x": -2678787.615146979,
            "y": 4607688.933081588,
            "z": 3503587.8865285018
        },
        {
            "x": -2678787.615146979,
            "y": 4607688.933081588,
            "z": 3503587.8865285018
        },
        {
            "x": -2678787.3357920684,
            "y": 4607688.471080984,
            "z": 3503587.5316781
        },
        {
            "x": -2678787.3357920684,
            "y": 4607688.471080984,
            "z": 3503587.5316781
        },
        {
            "x": -2678787.0763009256,
            "y": 4607688.006231328,
            "z": 3503587.1817714334
        },
        {
            "x": -2678787.0763009256,
            "y": 4607688.006231328,
            "z": 3503587.1817714334
        },
        {
            "x": -2678786.8111543157,
            "y": 4607687.550161913,
            "z": 3503586.848307962
        },
        {
            "x": -2678786.8111543157,
            "y": 4607687.550161913,
            "z": 3503586.848307962
        },
        {
            "x": -2678786.526081803,
            "y": 4607687.115342678,
            "z": 3503586.520337529
        },
        {
            "x": -2678786.2647864316,
            "y": 4607686.684405642,
            "z": 3503586.1891071983
        },
        {
            "x": -2678786.2647864316,
            "y": 4607686.684405642,
            "z": 3503586.1891071983
        },
        {
            "x": -2678786.0309054293,
            "y": 4607686.226591084,
            "z": 3503585.8649818897
        },
        {
            "x": -2678786.0309054293,
            "y": 4607686.226591084,
            "z": 3503585.8649818897
        },
        {
            "x": -2678786.0309054293,
            "y": 4607686.226591084,
            "z": 3503585.8649818897
        },
        {
            "x": -2678785.828003503,
            "y": 4607685.766538551,
            "z": 3503585.536498078
        },
        {
            "x": -2678785.621455135,
            "y": 4607685.300213916,
            "z": 3503585.1899231654
        },
        {
            "x": -2678785.621455135,
            "y": 4607685.300213916,
            "z": 3503585.1899231654
        },
        {
            "x": -2678785.40315626,
            "y": 4607684.850693673,
            "z": 3503584.835693929
        },
        {
            "x": -2678785.40315626,
            "y": 4607684.850693673,
            "z": 3503584.835693929
        },
        {
            "x": -2678785.161571577,
            "y": 4607684.416644333,
            "z": 3503584.482563304
        },
        {
            "x": -2678784.8944676807,
            "y": 4607683.99422428,
            "z": 3503584.1409321325
        },
        {
            "x": -2678784.8944676807,
            "y": 4607683.99422428,
            "z": 3503584.1409321325
        },
        {
            "x": -2678784.610306493,
            "y": 4607683.579480557,
            "z": 3503583.811349718
        },
        {
            "x": -2678784.610306493,
            "y": 4607683.579480557,
            "z": 3503583.811349718
        },
        {
            "x": -2678784.3341872324,
            "y": 4607683.16006147,
            "z": 3503583.481767304
        },
        {
            "x": -2678784.3341872324,
            "y": 4607683.16006147,
            "z": 3503583.481767304
        },
        {
            "x": -2678784.0724718682,
            "y": 4607682.728402025,
            "z": 3503583.149987673
        },
        {
            "x": -2678784.0724718682,
            "y": 4607682.728402025,
            "z": 3503583.149987673
        },
        {
            "x": -2678783.820540514,
            "y": 4607682.27655573,
            "z": 3503582.8099684822
        },
        {
            "x": -2678783.820540514,
            "y": 4607682.27655573,
            "z": 3503582.8099684822
        },
        {
            "x": -2678783.820540514,
            "y": 4607682.27655573,
            "z": 3503582.8099684822
        },
        {
            "x": -2678783.564695791,
            "y": 4607681.817978188,
            "z": 3503582.4781529144
        },
        {
            "x": -2678783.302202578,
            "y": 4607681.366472803,
            "z": 3503582.1348378984
        },
        {
            "x": -2678783.302202578,
            "y": 4607681.366472803,
            "z": 3503582.1348378984
        },
        {
            "x": -2678783.0202656095,
            "y": 4607680.918538866,
            "z": 3503581.7871284503
        },
        {
            "x": -2678782.722664797,
            "y": 4607680.480678052,
            "z": 3503581.439968306
        },
        {
            "x": -2678782.4331059093,
            "y": 4607680.038141874,
            "z": 3503581.092808163
        },
        {
            "x": -2678782.4331059093,
            "y": 4607680.038141874,
            "z": 3503581.092808163
        },
        {
            "x": -2678782.1499089855,
            "y": 4607679.588040705,
            "z": 3503580.7434508028
        },
        {
            "x": -2678782.1499089855,
            "y": 4607679.588040705,
            "z": 3503580.7434508028
        },
        {
            "x": -2678781.884957971,
            "y": 4607679.11379974,
            "z": 3503580.3864031853
        },
        {
            "x": -2678781.884957971,
            "y": 4607679.11379974,
            "z": 3503580.3864031853
        },
        {
            "x": -2678781.6263263896,
            "y": 4607678.65042862,
            "z": 3503580.07758649
        },
        {
            "x": -2678781.6263263896,
            "y": 4607678.65042862,
            "z": 3503580.07758649
        },
        {
            "x": -2678781.364397017,
            "y": 4607678.162877116,
            "z": 3503579.7539385804
        },
        {
            "x": -2678781.364397017,
            "y": 4607678.162877116,
            "z": 3503579.7539385804
        },
        {
            "x": -2678781.100305577,
            "y": 4607677.690114691,
            "z": 3503579.4379809205
        },
        {
            "x": -2678780.8392875222,
            "y": 4607677.222638696,
            "z": 3503579.1127210343
        },
        {
            "x": -2678780.8392875222,
            "y": 4607677.222638696,
            "z": 3503579.1127210343
        },
        {
            "x": -2678780.5792429084,
            "y": 4607676.756837083,
            "z": 3503578.7754124054
        },
        {
            "x": -2678780.5792429084,
            "y": 4607676.756837083,
            "z": 3503578.7754124054
        },
        {
            "x": -2678780.315551853,
            "y": 4607676.284763359,
            "z": 3503578.4200126897
        },
        {
            "x": -2678780.315551853,
            "y": 4607676.284763359,
            "z": 3503578.4200126897
        },
        {
            "x": -2678780.0467588087,
            "y": 4607675.822421851,
            "z": 3503578.068458102
        },
        {
            "x": -2678780.0467588087,
            "y": 4607675.822421851,
            "z": 3503578.068458102
        },
        {
            "x": -2678779.772925895,
            "y": 4607675.351411438,
            "z": 3503577.710311866
        },
        {
            "x": -2678779.772925895,
            "y": 4607675.351411438,
            "z": 3503577.710311866
        },
        {
            "x": -2678779.5033549885,
            "y": 4607674.869223994,
            "z": 3503577.3472218937
        },
        {
            "x": -2678779.5033549885,
            "y": 4607674.869223994,
            "z": 3503577.3472218937
        },
        {
            "x": -2678779.22497354,
            "y": 4607674.408897764,
            "z": 3503576.9803227456
        },
        {
            "x": -2678779.22497354,
            "y": 4607674.408897764,
            "z": 3503576.9803227456
        },
        {
            "x": -2678778.9321168857,
            "y": 4607673.942181194,
            "z": 3503576.618331382
        },
        {
            "x": -2678778.9321168857,
            "y": 4607673.942181194,
            "z": 3503576.618331382
        },
        {
            "x": -2678778.65318199,
            "y": 4607673.480902989,
            "z": 3503576.264030275
        },
        {
            "x": -2678778.65318199,
            "y": 4607673.480902989,
            "z": 3503576.264030275
        },
        {
            "x": -2678778.370815836,
            "y": 4607672.995214842,
            "z": 3503575.908045301
        },
        {
            "x": -2678778.370815836,
            "y": 4607672.995214842,
            "z": 3503575.908045301
        },
        {
            "x": -2678778.0919430554,
            "y": 4607672.515535525,
            "z": 3503575.5433074166
        },
        {
            "x": -2678778.0919430554,
            "y": 4607672.515535525,
            "z": 3503575.5433074166
        },
        {
            "x": -2678777.804188392,
            "y": 4607672.0390867395,
            "z": 3503575.1774709243
        },
        {
            "x": -2678777.5275490168,
            "y": 4607671.563249023,
            "z": 3503574.8023322215
        },
        {
            "x": -2678777.5275490168,
            "y": 4607671.563249023,
            "z": 3503574.8023322215
        },
        {
            "x": -2678777.2468628213,
            "y": 4607671.0804505125,
            "z": 3503574.4485444594
        },
        {
            "x": -2678777.2468628213,
            "y": 4607671.0804505125,
            "z": 3503574.4485444594
        },
        {
            "x": -2678776.9637280414,
            "y": 4607670.611948219,
            "z": 3503574.0887503107
        },
        {
            "x": -2678776.9637280414,
            "y": 4607670.611948219,
            "z": 3503574.0887503107
        },
        {
            "x": -2678776.6708714054,
            "y": 4607670.145231638,
            "z": 3503573.7267589467
        },
        {
            "x": -2678776.6708714054,
            "y": 4607670.145231638,
            "z": 3503573.7267589467
        },
        {
            "x": -2678776.3989853687,
            "y": 4607669.677569968,
            "z": 3503573.3445152584
        },
        {
            "x": -2678776.3989853687,
            "y": 4607669.677569968,
            "z": 3503573.3445152584
        },
        {
            "x": -2678776.135919103,
            "y": 4607669.225078875,
            "z": 3503572.9738069586
        },
        {
            "x": -2678775.8675460527,
            "y": 4607668.763459769,
            "z": 3503572.622801679
        },
        {
            "x": -2678775.8675460527,
            "y": 4607668.763459769,
            "z": 3503572.622801679
        },
        {
            "x": -2678775.610154824,
            "y": 4607668.302222177,
            "z": 3503572.2756415275
        },
        {
            "x": -2678775.610154824,
            "y": 4607668.302222177,
            "z": 3503572.2756415275
        },
        {
            "x": -2678775.3588390015,
            "y": 4607667.832926753,
            "z": 3503571.912587526
        },
        {
            "x": -2678775.0817175293,
            "y": 4607667.374767728,
            "z": 3503571.5473363097
        },
        {
            "x": -2678775.0817175293,
            "y": 4607667.374767728,
            "z": 3503571.5473363097
        },
        {
            "x": -2678774.8071780954,
            "y": 4607666.902542044,
            "z": 3503571.1749441423
        },
        {
            "x": -2678774.8071780954,
            "y": 4607666.902542044,
            "z": 3503571.1749441423
        },
        {
            "x": -2678774.5248832996,
            "y": 4607666.435484561,
            "z": 3503570.8162486088
        },
        {
            "x": -2678774.2492369777,
            "y": 4607665.961354955,
            "z": 3503570.469052491
        },
        {
            "x": -2678774.2492369777,
            "y": 4607665.961354955,
            "z": 3503570.469052491
        },
        {
            "x": -2678773.983374605,
            "y": 4607665.467038536,
            "z": 3503570.1136168092
        },
        {
            "x": -2678773.983374605,
            "y": 4607665.467038536,
            "z": 3503570.1136168092
        },
        {
            "x": -2678773.701490544,
            "y": 4607664.9636717,
            "z": 3503569.747744349
        },
        {
            "x": -2678773.701490544,
            "y": 4607664.9636717,
            "z": 3503569.747744349
        },
        {
            "x": -2678773.416389281,
            "y": 4607664.491786906,
            "z": 3503569.372056361
        },
        {
            "x": -2678773.416389281,
            "y": 4607664.491786906,
            "z": 3503569.372056361
        },
        {
            "x": -2678773.114097352,
            "y": 4607664.027348875,
            "z": 3503569.021564412
        },
        {
            "x": -2678773.114097352,
            "y": 4607664.027348875,
            "z": 3503569.021564412
        },
        {
            "x": -2678772.8146739956,
            "y": 4607663.549337052,
            "z": 3503568.6776281344
        },
        {
            "x": -2678772.5360784936,
            "y": 4607663.033118835,
            "z": 3503568.3188606435
        },
        {
            "x": -2678772.5360784936,
            "y": 4607663.033118835,
            "z": 3503568.3188606435
        },
        {
            "x": -2678772.293635254,
            "y": 4607662.5235611405,
            "z": 3503567.949179023
        },
        {
            "x": -2678772.293635254,
            "y": 4607662.5235611405,
            "z": 3503567.949179023
        },
        {
            "x": -2678772.0508433757,
            "y": 4607662.031911692,
            "z": 3503567.5762375547
        },
        {
            "x": -2678772.0508433757,
            "y": 4607662.031911692,
            "z": 3503567.5762375547
        },
        {
            "x": -2678771.7805659203,
            "y": 4607661.548508991,
            "z": 3503567.1989016584
        },
        {
            "x": -2678771.7805659203,
            "y": 4607661.548508991,
            "z": 3503567.1989016584
        },
        {
            "x": -2678771.5041417065,
            "y": 4607661.054533481,
            "z": 3503566.8401701394
        },
        {
            "x": -2678771.5041417065,
            "y": 4607661.054533481,
            "z": 3503566.8401701394
        },
        {
            "x": -2678771.215762255,
            "y": 4607660.558502085,
            "z": 3503566.489642199
        },
        {
            "x": -2678771.215762255,
            "y": 4607660.558502085,
            "z": 3503566.489642199
        },
        {
            "x": -2678770.944859994,
            "y": 4607660.055516788,
            "z": 3503566.127614852
        },
        {
            "x": -2678770.944859994,
            "y": 4607660.055516788,
            "z": 3503566.127614852
        },
        {
            "x": -2678770.6630472825,
            "y": 4607659.570780615,
            "z": 3503565.7590318373
        },
        {
            "x": -2678770.6630472825,
            "y": 4607659.570780615,
            "z": 3503565.7590318373
        },
        {
            "x": -2678770.3797594267,
            "y": 4607659.102014995,
            "z": 3503565.3723937576
        },
        {
            "x": -2678770.078707851,
            "y": 4607658.6397104105,
            "z": 3503564.983558465
        },
        {
            "x": -2678770.078707851,
            "y": 4607658.6397104105,
            "z": 3503564.983558465
        },
        {
            "x": -2678769.7760384106,
            "y": 4607658.156115086,
            "z": 3503564.582089184
        },
        {
            "x": -2678769.7760384106,
            "y": 4607658.156115086,
            "z": 3503564.582089184
        },
        {
            "x": -2678769.5028831065,
            "y": 4607657.649254425,
            "z": 3503564.1904713926
        },
        {
            "x": -2678769.5028831065,
            "y": 4607657.649254425,
            "z": 3503564.1904713926
        },
        {
            "x": -2678769.224554479,
            "y": 4607657.133495321,
            "z": 3503563.805409264
        },
        {
            "x": -2678769.224554479,
            "y": 4607657.133495321,
            "z": 3503563.805409264
        },
        {
            "x": -2678768.9188830312,
            "y": 4607656.663244227,
            "z": 3503563.4105316396
        },
        {
            "x": -2678768.9188830312,
            "y": 4607656.663244227,
            "z": 3503563.4105316396
        },
        {
            "x": -2678768.5971991974,
            "y": 4607656.220974429,
            "z": 3503563.01294349
        },
        {
            "x": -2678768.264462205,
            "y": 4607655.759692459,
            "z": 3503562.614220747
        },
        {
            "x": -2678768.264462205,
            "y": 4607655.759692459,
            "z": 3503562.614220747
        },
        {
            "x": -2678767.962919298,
            "y": 4607655.278034796,
            "z": 3503562.2275467045
        },
        {
            "x": -2678767.962919298,
            "y": 4607655.278034796,
            "z": 3503562.2275467045
        },
        {
            "x": -2678767.671313401,
            "y": 4607654.776453669,
            "z": 3503561.8594770157
        },
        {
            "x": -2678767.383969475,
            "y": 4607654.263695534,
            "z": 3503561.486463588
        },
        {
            "x": -2678767.383969475,
            "y": 4607654.263695534,
            "z": 3503561.486463588
        },
        {
            "x": -2678767.101111982,
            "y": 4607653.758654361,
            "z": 3503561.1326398063
        },
        {
            "x": -2678767.101111982,
            "y": 4607653.758654361,
            "z": 3503561.1326398063
        },
        {
            "x": -2678766.8337030704,
            "y": 4607653.261677878,
            "z": 3503560.761859586
        },
        {
            "x": -2678766.5769894053,
            "y": 4607652.764590057,
            "z": 3503560.3812278905
        },
        {
            "x": -2678766.5769894053,
            "y": 4607652.764590057,
            "z": 3503560.3812278905
        },
        {
            "x": -2678766.3041919945,
            "y": 4607652.2768528955,
            "z": 3503560.0005961955
        },
        {
            "x": -2678766.3041919945,
            "y": 4607652.2768528955,
            "z": 3503560.0005961955
        },
        {
            "x": -2678766.022665831,
            "y": 4607651.792609584,
            "z": 3503559.645709796
        },
        {
            "x": -2678765.750861494,
            "y": 4607651.30658058,
            "z": 3503559.29302061
        },
        {
            "x": -2678765.750861494,
            "y": 4607651.30658058,
            "z": 3503559.29302061
        },
        {
            "x": -2678765.4893520684,
            "y": 4607650.819751635,
            "z": 3503558.9699218385
        },
        {
            "x": -2678765.4893520684,
            "y": 4607650.819751635,
            "z": 3503558.9699218385
        },
        {
            "x": -2678765.2584015243,
            "y": 4607650.329962373,
            "z": 3503558.6419153265
        },
        {
            "x": -2678765.010804507,
            "y": 4607649.811540119,
            "z": 3503558.3187805424
        },
        {
            "x": -2678765.010804507,
            "y": 4607649.811540119,
            "z": 3503558.3187805424
        },
        {
            "x": -2678764.7587924185,
            "y": 4607649.304031532,
            "z": 3503557.9737455957
        },
        {
            "x": -2678764.7587924185,
            "y": 4607649.304031532,
            "z": 3503557.9737455957
        },
        {
            "x": -2678764.462289385,
            "y": 4607648.831042773,
            "z": 3503557.5936632
        },
        {
            "x": -2678764.462289385,
            "y": 4607648.831042773,
            "z": 3503557.5936632
        },
        {
            "x": -2678764.1301255417,
            "y": 4607648.370746511,
            "z": 3503557.222333666
        },
        {
            "x": -2678764.1301255417,
            "y": 4607648.370746511,
            "z": 3503557.222333666
        },
        {
            "x": -2678763.8075500876,
            "y": 4607647.908435022,
            "z": 3503556.866348639
        },
        {
            "x": -2678763.8075500876,
            "y": 4607647.908435022,
            "z": 3503556.866348639
        },
        {
            "x": -2678763.51833068,
            "y": 4607647.41095884,
            "z": 3503556.514722035
        },
        {
            "x": -2678763.2583481027,
            "y": 4607646.926756209,
            "z": 3503556.1669765613
        },
        {
            "x": -2678763.2583481027,
            "y": 4607646.926756209,
            "z": 3503556.1669765613
        },
        {
            "x": -2678762.988981965,
            "y": 4607646.463428943,
            "z": 3503555.7880287864
        },
        {
            "x": -2678762.988981965,
            "y": 4607646.463428943,
            "z": 3503555.7880287864
        },
        {
            "x": -2678762.699537035,
            "y": 4607646.021088379,
            "z": 3503555.3877301784
        },
        {
            "x": -2678762.412058611,
            "y": 4607645.582130322,
            "z": 3503555.0033253846
        },
        {
            "x": -2678762.412058611,
            "y": 4607645.582130322,
            "z": 3503555.0033253846
        },
        {
            "x": -2678762.114653556,
            "y": 4607645.126097808,
            "z": 3503554.632581172
        },
        {
            "x": -2678761.8158457866,
            "y": 4607644.630636836,
            "z": 3503554.265610063
        },
        {
            "x": -2678761.549338882,
            "y": 4607644.116704109,
            "z": 3503553.885491669
        },
        {
            "x": -2678761.549338882,
            "y": 4607644.116704109,
            "z": 3503553.885491669
        },
        {
            "x": -2678761.2917851834,
            "y": 4607643.6181714935,
            "z": 3503553.5037613786
        },
        {
            "x": -2678761.2917851834,
            "y": 4607643.6181714935,
            "z": 3503553.5037613786
        },
        {
            "x": -2678761.2917851834,
            "y": 4607643.6181714935,
            "z": 3503553.5037613786
        },
        {
            "x": -2678761.0324894628,
            "y": 4607643.135150327,
            "z": 3503553.130270651
        },
        {
            "x": -2678760.761371982,
            "y": 4607642.650302804,
            "z": 3503552.751836188
        },
        {
            "x": -2678760.761371982,
            "y": 4607642.650302804,
            "z": 3503552.751836188
        },
        {
            "x": -2678760.4786479133,
            "y": 4607642.1454912,
            "z": 3503552.3848650763
        },
        {
            "x": -2678760.4786479133,
            "y": 4607642.1454912,
            "z": 3503552.3848650763
        },
        {
            "x": -2678760.4786479133,
            "y": 4607642.1454912,
            "z": 3503552.3848650763
        },
        {
            "x": -2678760.2046722374,
            "y": 4607641.6372195715,
            "z": 3503552.032139848
        },
        {
            "x": -2678759.929221418,
            "y": 4607641.144918444,
            "z": 3503551.661359623
        },
        {
            "x": -2678759.929221418,
            "y": 4607641.144918444,
            "z": 3503551.661359623
        },
        {
            "x": -2678759.647962135,
            "y": 4607640.661134197,
            "z": 3503551.280178643
        },
        {
            "x": -2678759.647962135,
            "y": 4607640.661134197,
            "z": 3503551.280178643
        },
        {
            "x": -2678759.3499943432,
            "y": 4607640.16711805,
            "z": 3503550.9143061326
        },
        {
            "x": -2678759.0586749967,
            "y": 4607639.666029842,
            "z": 3503550.559932982
        },
        {
            "x": -2678759.0586749967,
            "y": 4607639.666029842,
            "z": 3503550.559932982
        },
        {
            "x": -2678758.790712598,
            "y": 4607639.16810147,
            "z": 3503550.2017507227
        },
        {
            "x": -2678758.790712598,
            "y": 4607639.16810147,
            "z": 3503550.2017507227
        },
        {
            "x": -2678758.5240101637,
            "y": 4607638.672340327,
            "z": 3503549.845216376
        },
        {
            "x": -2678758.5240101637,
            "y": 4607638.672340327,
            "z": 3503549.845216376
        },
        {
            "x": -2678758.2489793287,
            "y": 4607638.180761614,
            "z": 3503549.474985451
        },
        {
            "x": -2678758.2489793287,
            "y": 4607638.180761614,
            "z": 3503549.474985451
        },
        {
            "x": -2678757.9735285025,
            "y": 4607637.688460491,
            "z": 3503549.1042052214
        },
        {
            "x": -2678757.9735285025,
            "y": 4607637.688460491,
            "z": 3503549.1042052214
        },
        {
            "x": -2678757.6912244125,
            "y": 4607637.184371315,
            "z": 3503548.737783396
        },
        {
            "x": -2678757.410600278,
            "y": 4607636.683171775,
            "z": 3503548.3735587858
        },
        {
            "x": -2678757.410600278,
            "y": 4607636.683171775,
            "z": 3503548.3735587858
        },
        {
            "x": -2678757.1005955804,
            "y": 4607636.205466976,
            "z": 3503547.986335464
        },
        {
            "x": -2678757.1005955804,
            "y": 4607636.205466976,
            "z": 3503547.986335464
        },
        {
            "x": -2678756.772807563,
            "y": 4607635.7341893045,
            "z": 3503547.556923826
        },
        {
            "x": -2678756.4743380602,
            "y": 4607635.257817968,
            "z": 3503547.160947679
        },
        {
            "x": -2678756.4743380602,
            "y": 4607635.257817968,
            "z": 3503547.160947679
        },
        {
            "x": -2678756.1846169457,
            "y": 4607634.777986617,
            "z": 3503546.779217408
        },
        {
            "x": -2678756.1846169457,
            "y": 4607634.777986617,
            "z": 3503546.779217408
        },
        {
            "x": -2678755.9087461294,
            "y": 4607634.2849630825,
            "z": 3503546.40788788
        },
        {
            "x": -2678755.651345489,
            "y": 4607633.786693865,
            "z": 3503546.053001439
        },
        {
            "x": -2678755.651345489,
            "y": 4607633.786693865,
            "z": 3503546.053001439
        },
        {
            "x": -2678755.3917021197,
            "y": 4607633.247551435,
            "z": 3503545.700789444
        },
        {
            "x": -2678755.3917021197,
            "y": 4607633.247551435,
            "z": 3503545.700789444
        },
        {
            "x": -2678755.1285653943,
            "y": 4607632.702400229,
            "z": 3503545.3573302696
        },
        {
            "x": -2678755.1285653943,
            "y": 4607632.702400229,
            "z": 3503545.3573302696
        },
        {
            "x": -2678754.857182006,
            "y": 4607632.143064243,
            "z": 3503545.029728828
        },
        {
            "x": -2678754.857182006,
            "y": 4607632.143064243,
            "z": 3503545.029728828
        },
        {
            "x": -2678754.573055162,
            "y": 4607631.598824284,
            "z": 3503544.6665307293
        },
        {
            "x": -2678754.573055162,
            "y": 4607631.598824284,
            "z": 3503544.6665307293
        },
        {
            "x": -2678754.3114349125,
            "y": 4607631.093297139,
            "z": 3503544.2661601524
        },
        {
            "x": -2678754.067076558,
            "y": 4607630.598953798,
            "z": 3503543.837883197
        },
        {
            "x": -2678754.067076558,
            "y": 4607630.598953798,
            "z": 3503543.837883197
        },
        {
            "x": -2678753.812156425,
            "y": 4607630.104951314,
            "z": 3503543.406310425
        },
        {
            "x": -2678753.812156425,
            "y": 4607630.104951314,
            "z": 3503543.406310425
        },
        {
            "x": -2678753.5213707904,
            "y": 4607629.604781183,
            "z": 3503542.999348218
        },
        {
            "x": -2678753.5213707904,
            "y": 4607629.604781183,
            "z": 3503542.999348218
        },
        {
            "x": -2678753.204574883,
            "y": 4607629.096887247,
            "z": 3503542.6060465267
        },
        {
            "x": -2678753.204574883,
            "y": 4607629.096887247,
            "z": 3503542.6060465267
        },
        {
            "x": -2678752.8929626164,
            "y": 4607628.560893955,
            "z": 3503542.238453989
        },
        {
            "x": -2678752.8929626164,
            "y": 4607628.560893955,
            "z": 3503542.238453989
        },
        {
            "x": -2678752.5815034816,
            "y": 4607628.025164061,
            "z": 3503541.8977052486
        },
        {
            "x": -2678752.5815034816,
            "y": 4607628.025164061,
            "z": 3503541.8977052486
        },
        {
            "x": -2678752.3013810427,
            "y": 4607627.506319777,
            "z": 3503541.5635841964
        },
        {
            "x": -2678752.3013810427,
            "y": 4607627.506319777,
            "z": 3503541.5635841964
        },
        {
            "x": -2678752.0449207914,
            "y": 4607627.065191531,
            "z": 3503541.254803219
        },
        {
            "x": -2678752.0449207914,
            "y": 4607627.065191531,
            "z": 3503541.254803219
        },
        {
            "x": -2678751.8290463598,
            "y": 4607626.693873523,
            "z": 3503540.9724609326
        },
        {
            "x": -2678751.8290463598,
            "y": 4607626.693873523,
            "z": 3503540.9724609326
        },
        {
            "x": -2678751.6574465483,
            "y": 4607626.380202967,
            "z": 3503540.684220502
        },
        {
            "x": -2678751.6574465483,
            "y": 4607626.380202967,
            "z": 3503540.684220502
        },
        {
            "x": -2678751.5265566125,
            "y": 4607626.09954051,
            "z": 3503540.401545218
        },
        {
            "x": -2678751.5265566125,
            "y": 4607626.09954051,
            "z": 3503540.401545218
        },
        {
            "x": -2678751.5265566125,
            "y": 4607626.09954051,
            "z": 3503540.401545218
        },
        {
            "x": -2678751.4185612486,
            "y": 4607625.839750445,
            "z": 3503540.1649393793
        },
        {
            "x": -2678751.4185612486,
            "y": 4607625.839750445,
            "z": 3503540.1649393793
        },
        {
            "x": -2678751.2942867773,
            "y": 4607625.607482546,
            "z": 3503539.951917567
        },
        {
            "x": -2678751.2942867773,
            "y": 4607625.607482546,
            "z": 3503539.951917567
        },
        {
            "x": -2678751.1434579995,
            "y": 4607625.403570568,
            "z": 3503539.772880503
        },
        {
            "x": -2678751.1434579995,
            "y": 4607625.403570568,
            "z": 3503539.772880503
        },
        {
            "x": -2678751.1434579995,
            "y": 4607625.403570568,
            "z": 3503539.772880503
        },
        {
            "x": -2678750.972436793,
            "y": 4607625.22044957,
            "z": 3503539.62563097
        },
        {
            "x": -2678750.827670081,
            "y": 4607625.063980237,
            "z": 3503539.5288453028
        },
        {
            "x": -2678750.827670081,
            "y": 4607625.063980237,
            "z": 3503539.5288453028
        },
        {
            "x": -2678750.7117398228,
            "y": 4607624.920095956,
            "z": 3503539.4753825464
        },
        {
            "x": -2678750.7117398228,
            "y": 4607624.920095956,
            "z": 3503539.4753825464
        },
        {
            "x": -2678750.6390393763,
            "y": 4607624.81355423,
            "z": 3503539.4307807586
        },
        {
            "x": -2678750.6390393763,
            "y": 4607624.81355423,
            "z": 3503539.4307807586
        },
        {
            "x": -2678750.570948475,
            "y": 4607624.751956738,
            "z": 3503539.359956591
        },
        {
            "x": -2678750.570948475,
            "y": 4607624.751956738,
            "z": 3503539.359956591
        },
        {
            "x": -2678750.5064937165,
            "y": 4607624.733629156,
            "z": 3503539.274958677
        },
        {
            "x": -2678750.5064937165,
            "y": 4607624.733629156,
            "z": 3503539.274958677
        },
        {
            "x": -2678750.447785376,
            "y": 4607624.72518579,
            "z": 3503539.210798264
        },
        {
            "x": -2678750.447785376,
            "y": 4607624.72518579,
            "z": 3503539.210798264
        },
        {
            "x": -2678750.397691982,
            "y": 4607624.71305293,
            "z": 3503539.1740309475
        },
        {
            "x": -2678750.397691982,
            "y": 4607624.71305293,
            "z": 3503539.1740309475
        },
        {
            "x": -2678750.3553735553,
            "y": 4607624.69578576,
            "z": 3503539.163558117
        },
        {
            "x": -2678750.3553735553,
            "y": 4607624.69578576,
            "z": 3503539.163558117
        },
        {
            "x": -2678750.318934975,
            "y": 4607624.68863231,
            "z": 3503539.160775543
        },
        {
            "x": -2678750.304173326,
            "y": 4607624.681749074,
            "z": 3503539.1519866786
        },
        {
            "x": -2678750.304173326,
            "y": 4607624.681749074,
            "z": 3503539.1519866786
        },
        {
            "x": -2678750.2923516026,
            "y": 4607624.679922698,
            "z": 3503539.1470429422
        },
        {
            "x": -2678750.2923516026,
            "y": 4607624.679922698,
            "z": 3503539.1470429422
        },
        {
            "x": -2678750.313608544,
            "y": 4607624.679470504,
            "z": 3503539.1404873473
        },
        {
            "x": -2678750.3317921334,
            "y": 4607624.673731943,
            "z": 3503539.143233867
        },
        {
            "x": -2678750.3317921334,
            "y": 4607624.673731943,
            "z": 3503539.143233867
        },
        {
            "x": -2678750.3452224145,
            "y": 4607624.6598173855,
            "z": 3503539.1530852867
        },
        {
            "x": -2678750.3452224145,
            "y": 4607624.6598173855,
            "z": 3503539.1530852867
        },
        {
            "x": -2678750.350610876,
            "y": 4607624.650578127,
            "z": 3503539.1629367047
        },
        {
            "x": -2678750.3314538803,
            "y": 4607624.654642365,
            "z": 3503539.172238821
        },
        {
            "x": -2678750.3314538803,
            "y": 4607624.654642365,
            "z": 3503539.172238821
        },
        {
            "x": -2678750.3097769488,
            "y": 4607624.654372153,
            "z": 3503539.178245112
        },
        {
            "x": -2678750.3097769488,
            "y": 4607624.654372153,
            "z": 3503539.178245112
        },
        {
            "x": -2678750.289913403,
            "y": 4607624.657221077,
            "z": 3503539.1733013755
        },
        {
            "x": -2678750.289913403,
            "y": 4607624.657221077,
            "z": 3503539.1733013755
        },
        {
            "x": -2678750.273543197,
            "y": 4607624.666078775,
            "z": 3503539.1596048283
        },
        {
            "x": -2678750.273543197,
            "y": 4607624.666078775,
            "z": 3503539.1596048283
        },
        {
            "x": -2678750.265214814,
            "y": 4607624.670261172,
            "z": 3503539.1459082803
        },
        {
            "x": -2678750.265214814,
            "y": 4607624.670261172,
            "z": 3503539.1459082803
        },
        {
            "x": -2678750.2637396837,
            "y": 4607624.68623161,
            "z": 3503539.127853354
        },
        {
            "x": -2678750.245976084,
            "y": 4607624.692692578,
            "z": 3503539.125656138
        },
        {
            "x": -2678750.245976084,
            "y": 4607624.692692578,
            "z": 3503539.125656138
        },
        {
            "x": -2678750.203247037,
            "y": 4607624.711734633,
            "z": 3503539.1223603142
        },
        {
            "x": -2678750.203247037,
            "y": 4607624.711734633,
            "z": 3503539.1223603142
        },
        {
            "x": -2678750.1717149005,
            "y": 4607624.713020581,
            "z": 3503539.1393166315
        },
        {
            "x": -2678750.1717149005,
            "y": 4607624.713020581,
            "z": 3503539.1393166315
        },
        {
            "x": -2678750.1573215495,
            "y": 4607624.688263079,
            "z": 3503539.1737785717
        },
        {
            "x": -2678750.1573215495,
            "y": 4607624.688263079,
            "z": 3503539.1737785717
        },
        {
            "x": -2678750.1666336753,
            "y": 4607624.648757267,
            "z": 3503539.207691207
        },
        {
            "x": -2678750.1666336753,
            "y": 4607624.648757267,
            "z": 3503539.207691207
        },
        {
            "x": -2678750.194047654,
            "y": 4607624.621880064,
            "z": 3503539.214796107
        },
        {
            "x": -2678750.194047654,
            "y": 4607624.621880064,
            "z": 3503539.214796107
        },
        {
            "x": -2678750.246283316,
            "y": 4607624.619190007,
            "z": 3503539.203882133
        },
        {
            "x": -2678750.246283316,
            "y": 4607624.619190007,
            "z": 3503539.203882133
        },
        {
            "x": -2678750.309500724,
            "y": 4607624.616881509,
            "z": 3503539.1968132886
        },
        {
            "x": -2678750.309500724,
            "y": 4607624.616881509,
            "z": 3503539.1968132886
        },
        {
            "x": -2678750.3599230004,
            "y": 4607624.611072313,
            "z": 3503539.1968493415
        },
        {
            "x": -2678750.3599230004,
            "y": 4607624.611072313,
            "z": 3503539.1968493415
        },
        {
            "x": -2678750.3892217632,
            "y": 4607624.605944817,
            "z": 3503539.1902937475
        },
        {
            "x": -2678750.3892217632,
            "y": 4607624.605944817,
            "z": 3503539.1902937475
        },
        {
            "x": -2678750.4207538995,
            "y": 4607624.604658868,
            "z": 3503539.1733374293
        },
        {
            "x": -2678750.4207538995,
            "y": 4607624.604658868,
            "z": 3503539.1733374293
        },
        {
            "x": -2678750.461587827,
            "y": 4607624.600864842,
            "z": 3503539.158029023
        },
        {
            "x": -2678750.4756429247,
            "y": 4607624.606532765,
            "z": 3503539.1525720363
        },
        {
            "x": -2678750.4756429247,
            "y": 4607624.606532765,
            "z": 3503539.1525720363
        },
        {
            "x": -2678750.433572609,
            "y": 4607624.5156613365,
            "z": 3503539.100352099
        },
        {
            "x": -2678750.433572609,
            "y": 4607624.5156613365,
            "z": 3503539.100352099
        },
        {
            "x": -2678750.342402014,
            "y": 4607624.4143652655,
            "z": 3503539.0393072427
        },
        {
            "x": -2678750.258657795,
            "y": 4607624.325843047,
            "z": 3503539.0012971004
        },
        {
            "x": -2678750.258657795,
            "y": 4607624.325843047,
            "z": 3503539.0012971004
        },
        {
            "x": -2678750.19533054,
            "y": 4607624.235423814,
            "z": 3503538.9556327555
        },
        {
            "x": -2678750.19533054,
            "y": 4607624.235423814,
            "z": 3503538.9556327555
        },
        {
            "x": -2678750.1228349255,
            "y": 4607624.147742159,
            "z": 3503538.895173256
        },
        {
            "x": -2678750.1228349255,
            "y": 4607624.147742159,
            "z": 3503538.895173256
        },
        {
            "x": -2678750.0500527495,
            "y": 4607624.0595676,
            "z": 3503538.821017209
        },
        {
            "x": -2678750.0500527495,
            "y": 4607624.0595676,
            "z": 3503538.821017209
        },
        {
            "x": -2678749.9772705743,
            "y": 4607623.97139304,
            "z": 3503538.746861162
        },
        {
            "x": -2678749.9772705743,
            "y": 4607623.97139304,
            "z": 3503538.746861162
        },
        {
            "x": -2678749.9247719324,
            "y": 4607623.881091965,
            "z": 3503538.6781981555
        },
        {
            "x": -2678749.8796085594,
            "y": 4607623.784900278,
            "z": 3503538.5952892983
        },
        {
            "x": -2678749.8796085594,
            "y": 4607623.784900278,
            "z": 3503538.5952892983
        },
        {
            "x": -2678749.8251433983,
            "y": 4607623.6912166625,
            "z": 3503538.5107325315
        },
        {
            "x": -2678749.8251433983,
            "y": 4607623.6912166625,
            "z": 3503538.5107325315
        },
        {
            "x": -2678749.763762958,
            "y": 4607623.604146071,
            "z": 3503538.440970917
        },
        {
            "x": -2678749.7030890686,
            "y": 4607623.5182907935,
            "z": 3503538.385455151
        },
        {
            "x": -2678749.7030890686,
            "y": 4607623.5182907935,
            "z": 3503538.385455151
        },
        {
            "x": -2678749.6183517464,
            "y": 4607623.428060351,
            "z": 3503538.31950261
        },
        {
            "x": -2678749.6183517464,
            "y": 4607623.428060351,
            "z": 3503538.31950261
        },
        {
            "x": -2678749.519826183,
            "y": 4607623.332620991,
            "z": 3503538.23271257
        },
        {
            "x": -2678749.4264739193,
            "y": 4607623.246080039,
            "z": 3503538.139366937
        },
        {
            "x": -2678749.4264739193,
            "y": 4607623.246080039,
            "z": 3503538.139366937
        },
        {
            "x": -2678749.365360335,
            "y": 4607623.15946845,
            "z": 3503538.043310842
        },
        {
            "x": -2678749.365360335,
            "y": 4607623.15946845,
            "z": 3503538.043310842
        },
        {
            "x": -2678749.314388515,
            "y": 4607623.071793603,
            "z": 3503537.9500012686
        },
        {
            "x": -2678749.314388515,
            "y": 4607623.071793603,
            "z": 3503537.9500012686
        },
        {
            "x": -2678749.277471788,
            "y": 4607622.989786681,
            "z": 3503537.851234712
        },
        {
            "x": -2678749.277471788,
            "y": 4607622.989786681,
            "z": 3503537.851234712
        },
        {
            "x": -2678749.2302798694,
            "y": 4607622.908613511,
            "z": 3503537.762868876
        },
        {
            "x": -2678749.2302798694,
            "y": 4607622.908613511,
            "z": 3503537.762868876
        },
        {
            "x": -2678749.1833745134,
            "y": 4607622.827933248,
            "z": 3503537.6881995844
        },
        {
            "x": -2678749.1300358945,
            "y": 4607622.736187354,
            "z": 3503537.618437972
        },
        {
            "x": -2678749.1300358945,
            "y": 4607622.736187354,
            "z": 3503537.618437972
        },
        {
            "x": -2678749.067528916,
            "y": 4607622.647179034,
            "z": 3503537.5338812084
        },
        {
            "x": -2678749.067528916,
            "y": 4607622.647179034,
            "z": 3503537.5338812084
        },
        {
            "x": -2678749.013770308,
            "y": 4607622.554710731,
            "z": 3503537.463570292
        },
        {
            "x": -2678749.013770308,
            "y": 4607622.554710731,
            "z": 3503537.463570292
        },
        {
            "x": -2678748.935948268,
            "y": 4607622.457867264,
            "z": 3503537.3828225993
        },
        {
            "x": -2678748.935948268,
            "y": 4607622.457867264,
            "z": 3503537.3828225993
        },
        {
            "x": -2678748.848117893,
            "y": 4607622.362316551,
            "z": 3503537.2861811467
        },
        {
            "x": -2678748.848117893,
            "y": 4607622.362316551,
            "z": 3503537.2861811467
        },
        {
            "x": -2678748.7823844287,
            "y": 4607622.267758462,
            "z": 3503537.184082714
        },
        {
            "x": -2678748.7823844287,
            "y": 4607622.267758462,
            "z": 3503537.184082714
        },
        {
            "x": -2678748.7134441882,
            "y": 4607622.1676845085,
            "z": 3503537.1044336297
        },
        {
            "x": -2678748.7134441882,
            "y": 4607622.1676845085,
            "z": 3503537.1044336297
        },
        {
            "x": -2678748.6608741465,
            "y": 4607622.058752866,
            "z": 3503537.0384810874
        },
        {
            "x": -2678748.6608741465,
            "y": 4607622.058752866,
            "z": 3503537.0384810874
        },
        {
            "x": -2678748.5947404057,
            "y": 4607621.963506276,
            "z": 3503536.975824368
        },
        {
            "x": -2678748.5947404057,
            "y": 4607621.963506276,
            "z": 3503536.975824368
        },
        {
            "x": -2678748.532735154,
            "y": 4607621.856853206,
            "z": 3503536.9213711508
        },
        {
            "x": -2678748.532735154,
            "y": 4607621.856853206,
            "z": 3503536.9213711508
        },
        {
            "x": -2678748.477091763,
            "y": 4607621.742635204,
            "z": 3503536.8647207157
        },
        {
            "x": -2678748.477091763,
            "y": 4607621.742635204,
            "z": 3503536.8647207157
        },
        {
            "x": -2678748.412013162,
            "y": 4607621.630695775,
            "z": 3503536.819569606
        },
        {
            "x": -2678748.355181206,
            "y": 4607621.532941111,
            "z": 3503536.7585607963
        },
        {
            "x": -2678748.355181206,
            "y": 4607621.532941111,
            "z": 3503536.7585607963
        },
        {
            "x": -2678748.303522545,
            "y": 4607621.444084852,
            "z": 3503536.6909963987
        },
        {
            "x": -2678748.303522545,
            "y": 4607621.444084852,
            "z": 3503536.6909963987
        },
        {
            "x": -2678748.2373888046,
            "y": 4607621.348838259,
            "z": 3503536.6283396776
        },
        {
            "x": -2678748.169021695,
            "y": 4607621.249750124,
            "z": 3503536.576083672
        },
        {
            "x": -2678748.169021695,
            "y": 4607621.249750124,
            "z": 3503536.576083672
        },
        {
            "x": -2678748.110796347,
            "y": 4607621.149598735,
            "z": 3503536.526574185
        },
        {
            "x": -2678748.110796347,
            "y": 4607621.149598735,
            "z": 3503536.526574185
        },
        {
            "x": -2678748.062015587,
            "y": 4607621.084200396,
            "z": 3503536.4732916923
        },
        {
            "x": -2678748.039365269,
            "y": 4607621.082255861,
            "z": 3503536.491346604
        },
        {
            "x": -2678748.039365269,
            "y": 4607621.082255861,
            "z": 3503536.491346604
        },
        {
            "x": -2678748.0456651053,
            "y": 4607621.09309199,
            "z": 3503536.499586165
        },
        {
            "x": -2678748.0456651053,
            "y": 4607621.09309199,
            "z": 3503536.499586165
        },
        {
            "x": -2678748.0553868776,
            "y": 4607621.091306328,
            "z": 3503536.501783381
        },
        {
            "x": -2678748.057066834,
            "y": 4607621.094195962,
            "z": 3503536.503980597
        },
        {
            "x": -2678748.057066834,
            "y": 4607621.094195962,
            "z": 3503536.503980597
        },
        {
            "x": -2678748.0574868233,
            "y": 4607621.09491837,
            "z": 3503536.5045299013
        },
        {
            "x": -2678748.0574868233,
            "y": 4607621.09491837,
            "z": 3503536.5045299013
        },
        {
            "x": -2678748.0574248005,
            "y": 4607621.113319431,
            "z": 3503536.5149666783
        },
        {
            "x": -2678748.0561648333,
            "y": 4607621.111152206,
            "z": 3503536.5133187664
        },
        {
            "x": -2678748.0561648333,
            "y": 4607621.111152206,
            "z": 3503536.5133187664
        },
        {
            "x": -2678748.054484877,
            "y": 4607621.1082625715,
            "z": 3503536.51112155
        },
        {
            "x": -2678748.054484877,
            "y": 4607621.1082625715,
            "z": 3503536.51112155
        },
        {
            "x": -2678748.054484877,
            "y": 4607621.1082625715,
            "z": 3503536.51112155
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.0561648333,
            "y": 4607621.111152206,
            "z": 3503536.5133187664
        },
        {
            "x": -2678748.062946682,
            "y": 4607621.104309683,
            "z": 3503536.511670854
        },
        {
            "x": -2678748.062946682,
            "y": 4607621.104309683,
            "z": 3503536.511670854
        },
        {
            "x": -2678748.0540648876,
            "y": 4607621.107540162,
            "z": 3503536.510572246
        },
        {
            "x": -2678748.0540648876,
            "y": 4607621.107540162,
            "z": 3503536.510572246
        },
        {
            "x": -2678748.0509915384,
            "y": 4607621.102253802,
            "z": 3503536.5198743534
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.055324855,
            "y": 4607621.109707388,
            "z": 3503536.5122201582
        },
        {
            "x": -2678748.0540648876,
            "y": 4607621.107540162,
            "z": 3503536.510572246
        },
        {
            "x": -2678748.0540648876,
            "y": 4607621.107540162,
            "z": 3503536.510572246
        },
        {
            "x": -2678748.0625266926,
            "y": 4607621.103587274,
            "z": 3503536.51112155
        },
        {
            "x": -2678748.0625266926,
            "y": 4607621.103587274,
            "z": 3503536.51112155
        },
        {
            "x": -2678748.066860009,
            "y": 4607621.111040861,
            "z": 3503536.503467353
        },
        {
            "x": -2678748.066860009,
            "y": 4607621.111040861,
            "z": 3503536.503467353
        },
        {
            "x": -2678748.068119976,
            "y": 4607621.113208087,
            "z": 3503536.5051152655
        },
        {
            "x": -2678748.068119976,
            "y": 4607621.113208087,
            "z": 3503536.5051152655
        },
        {
            "x": -2678748.0685399654,
            "y": 4607621.113930495,
            "z": 3503536.5056645693
        },
        {
            "x": -2678748.0646266383,
            "y": 4607621.107199317,
            "z": 3503536.51386807
        },
        {
            "x": -2678748.0646266383,
            "y": 4607621.107199317,
            "z": 3503536.51386807
        },
        {
            "x": -2678748.0598733327,
            "y": 4607621.099023323,
            "z": 3503536.5209729616
        },
        {
            "x": -2678748.0598733327,
            "y": 4607621.099023323,
            "z": 3503536.5209729616
        },
        {
            "x": -2678748.058613365,
            "y": 4607621.096856097,
            "z": 3503536.5193250496
        },
        {
            "x": -2678748.058613365,
            "y": 4607621.096856097,
            "z": 3503536.5193250496
        },
        {
            "x": -2678748.057353398,
            "y": 4607621.094688871,
            "z": 3503536.517677137
        },
        {
            "x": -2678748.057353398,
            "y": 4607621.094688871,
            "z": 3503536.517677137
        },
        {
            "x": -2678748.0569334086,
            "y": 4607621.093966463,
            "z": 3503536.5171278333
        },
        {
            "x": -2678748.0569334086,
            "y": 4607621.093966463,
            "z": 3503536.5171278333
        },
        {
            "x": -2678748.057773387,
            "y": 4607621.095411279,
            "z": 3503536.5182264415
        },
        {
            "x": -2678748.057773387,
            "y": 4607621.095411279,
            "z": 3503536.5182264415
        },
        {
            "x": -2678748.0607133107,
            "y": 4607621.10046814,
            "z": 3503536.5220715697
        },
        {
            "x": -2678748.0607133107,
            "y": 4607621.10046814,
            "z": 3503536.5220715697
        },
        {
            "x": -2678748.0640732236,
            "y": 4607621.106247408,
            "z": 3503536.526466002
        },
        {
            "x": -2678748.0640732236,
            "y": 4607621.106247408,
            "z": 3503536.526466002
        },
        {
            "x": -2678748.06575318,
            "y": 4607621.109137043,
            "z": 3503536.5286632185
        },
        {
            "x": -2678748.065333191,
            "y": 4607621.108414634,
            "z": 3503536.528113914
        },
        {
            "x": -2678748.065333191,
            "y": 4607621.108414634,
            "z": 3503536.528113914
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.064493213,
            "y": 4607621.106969818,
            "z": 3503536.527015306
        },
        {
            "x": -2678748.0640732236,
            "y": 4607621.106247408,
            "z": 3503536.526466002
        },
        {
            "x": -2678748.0640732236,
            "y": 4607621.106247408,
            "z": 3503536.526466002
        },
        {
            "x": -2678748.0671465728,
            "y": 4607621.1115337685,
            "z": 3503536.5171638946
        },
        {
            "x": -2678748.0671465728,
            "y": 4607621.1115337685,
            "z": 3503536.5171638946
        },
        {
            "x": -2678748.0667265835,
            "y": 4607621.11081136,
            "z": 3503536.5166145903
        },
        {
            "x": -2678748.0667265835,
            "y": 4607621.11081136,
            "z": 3503536.5166145903
        },
        {
            "x": -2678748.0693799434,
            "y": 4607621.115375313,
            "z": 3503536.5067631775
        },
        {
            "x": -2678748.0693799434,
            "y": 4607621.115375313,
            "z": 3503536.5067631775
        },
        {
            "x": -2678748.0693799434,
            "y": 4607621.115375313,
            "z": 3503536.5067631775
        },
        {
            "x": -2678748.070219922,
            "y": 4607621.11682013,
            "z": 3503536.5078617856
        },
        {
            "x": -2678748.070219922,
            "y": 4607621.11682013,
            "z": 3503536.5078617856
        },
        {
            "x": -2678748.0638580625,
            "y": 4607621.124385062,
            "z": 3503536.5100590023
        },
        {
            "x": -2678748.0638580625,
            "y": 4607621.124385062,
            "z": 3503536.5100590023
        },
        {
            "x": -2678748.0616246914,
            "y": 4607621.120543517,
            "z": 3503536.520459719
        },
        {
            "x": -2678748.0612047026,
            "y": 4607621.119821109,
            "z": 3503536.5199104147
        },
        {
            "x": -2678748.0612047026,
            "y": 4607621.119821109,
            "z": 3503536.5199104147
        },
        {
            "x": -2678748.0612047026,
            "y": 4607621.119821109,
            "z": 3503536.5199104147
        },
        {
            "x": -2678748.0612047026,
            "y": 4607621.119821109,
            "z": 3503536.5199104147
        },
        {
            "x": -2678748.0616246914,
            "y": 4607621.120543517,
            "z": 3503536.520459719
        },
        {
            "x": -2678748.0700864964,
            "y": 4607621.116590629,
            "z": 3503536.521009023
        },
        {
            "x": -2678748.0700864964,
            "y": 4607621.116590629,
            "z": 3503536.521009023
        },
        {
            "x": -2678748.0731598455,
            "y": 4607621.12187699,
            "z": 3503536.511706914
        },
        {
            "x": -2678748.0731598455,
            "y": 4607621.12187699,
            "z": 3503536.511706914
        },
        {
            "x": -2678748.0723198676,
            "y": 4607621.120432173,
            "z": 3503536.5106083057
        },
        {
            "x": -2678748.0723198676,
            "y": 4607621.120432173,
            "z": 3503536.5106083057
        },
        {
            "x": -2678748.0723198676,
            "y": 4607621.120432173,
            "z": 3503536.5106083057
        },
        {
            "x": -2678748.0620446806,
            "y": 4607621.121265926,
            "z": 3503536.521009023
        },
        {
            "x": -2678748.0620446806,
            "y": 4607621.121265926,
            "z": 3503536.521009023
        },
        {
            "x": -2678748.0620446806,
            "y": 4607621.121265926,
            "z": 3503536.521009023
        },
        {
            "x": -2678748.062884659,
            "y": 4607621.122710743,
            "z": 3503536.522107631
        },
        {
            "x": -2678748.062884659,
            "y": 4607621.122710743,
            "z": 3503536.522107631
        },
        {
            "x": -2678748.063304648,
            "y": 4607621.123433152,
            "z": 3503536.5226569353
        },
        {
            "x": -2678748.063304648,
            "y": 4607621.123433152,
            "z": 3503536.5226569353
        },
        {
            "x": -2678748.063304648,
            "y": 4607621.123433152,
            "z": 3503536.5226569353
        },
        {
            "x": -2678748.063304648,
            "y": 4607621.123433152,
            "z": 3503536.5226569353
        },
        {
            "x": -2678748.0570048112,
            "y": 4607621.112597023,
            "z": 3503536.5144173745
        },
        {
            "x": -2678748.054413474,
            "y": 4607621.089632011,
            "z": 3503536.513832009
        },
        {
            "x": -2678748.054413474,
            "y": 4607621.089632011,
            "z": 3503536.513832009
        },
        {
            "x": -2678748.0578354094,
            "y": 4607621.077010218,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.0578354094,
            "y": 4607621.077010218,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.0557354637,
            "y": 4607621.073398175,
            "z": 3503536.505043144
        },
        {
            "x": -2678748.0526621146,
            "y": 4607621.068111815,
            "z": 3503536.514345252
        },
        {
            "x": -2678748.0526621146,
            "y": 4607621.068111815,
            "z": 3503536.514345252
        },
        {
            "x": -2678748.0458802665,
            "y": 4607621.074954337,
            "z": 3503536.5159931644
        },
        {
            "x": -2678748.049793594,
            "y": 4607621.081685516,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.049793594,
            "y": 4607621.081685516,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.053706921,
            "y": 4607621.088416693,
            "z": 3503536.499586165
        },
        {
            "x": -2678748.053706921,
            "y": 4607621.088416693,
            "z": 3503536.499586165
        },
        {
            "x": -2678748.0545468996,
            "y": 4607621.08986151,
            "z": 3503536.500684773
        },
        {
            "x": -2678748.0549668884,
            "y": 4607621.090583919,
            "z": 3503536.501234077
        },
        {
            "x": -2678748.0549668884,
            "y": 4607621.090583919,
            "z": 3503536.501234077
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.05741542,
            "y": 4607621.076287809,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.05741542,
            "y": 4607621.076287809,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.056575442,
            "y": 4607621.074842992,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.0481136376,
            "y": 4607621.078795881,
            "z": 3503536.505592448
        },
        {
            "x": -2678748.0481136376,
            "y": 4607621.078795881,
            "z": 3503536.505592448
        },
        {
            "x": -2678748.0476936484,
            "y": 4607621.078073473,
            "z": 3503536.505043144
        },
        {
            "x": -2678748.0476936484,
            "y": 4607621.078073473,
            "z": 3503536.505043144
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.058255398,
            "y": 4607621.077732626,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.058255398,
            "y": 4607621.077732626,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.05741542,
            "y": 4607621.076287809,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.05741542,
            "y": 4607621.076287809,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.056575442,
            "y": 4607621.074842992,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.056575442,
            "y": 4607621.074842992,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.056575442,
            "y": 4607621.074842992,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.056995431,
            "y": 4607621.0755654005,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.056995431,
            "y": 4607621.0755654005,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.05741542,
            "y": 4607621.076287809,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0514735505,
            "y": 4607621.08457515,
            "z": 3503536.5099868807
        },
        {
            "x": -2678748.0523135285,
            "y": 4607621.086019968,
            "z": 3503536.511085489
        },
        {
            "x": -2678748.0523135285,
            "y": 4607621.086019968,
            "z": 3503536.511085489
        },
        {
            "x": -2678748.0527335177,
            "y": 4607621.086742376,
            "z": 3503536.5116347927
        },
        {
            "x": -2678748.0527335177,
            "y": 4607621.086742376,
            "z": 3503536.5116347927
        },
        {
            "x": -2678748.0541269104,
            "y": 4607621.089139102,
            "z": 3503536.500135469
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.0592288026,
            "y": 4607621.079406944,
            "z": 3503536.496290341
        },
        {
            "x": -2678748.0592288026,
            "y": 4607621.079406944,
            "z": 3503536.496290341
        },
        {
            "x": -2678748.0588088133,
            "y": 4607621.078684536,
            "z": 3503536.4957410367
        },
        {
            "x": -2678748.0588088133,
            "y": 4607621.078684536,
            "z": 3503536.4957410367
        },
        {
            "x": -2678748.0503470083,
            "y": 4607621.082637425,
            "z": 3503536.4951917324
        },
        {
            "x": -2678748.0503470083,
            "y": 4607621.082637425,
            "z": 3503536.4951917324
        },
        {
            "x": -2678748.046433681,
            "y": 4607621.075906247,
            "z": 3503536.503395232
        },
        {
            "x": -2678748.046433681,
            "y": 4607621.075906247,
            "z": 3503536.503395232
        },
        {
            "x": -2678748.0455937027,
            "y": 4607621.074461429,
            "z": 3503536.502296624
        },
        {
            "x": -2678748.0455937027,
            "y": 4607621.074461429,
            "z": 3503536.502296624
        },
        {
            "x": -2678748.0455937027,
            "y": 4607621.074461429,
            "z": 3503536.502296624
        },
        {
            "x": -2678748.0455937027,
            "y": 4607621.074461429,
            "z": 3503536.502296624
        },
        {
            "x": -2678748.0455937027,
            "y": 4607621.074461429,
            "z": 3503536.502296624
        },
        {
            "x": -2678748.046013692,
            "y": 4607621.075183839,
            "z": 3503536.5028459276
        },
        {
            "x": -2678748.046013692,
            "y": 4607621.075183839,
            "z": 3503536.5028459276
        },
        {
            "x": -2678748.046013692,
            "y": 4607621.075183839,
            "z": 3503536.5028459276
        },
        {
            "x": -2678748.047273659,
            "y": 4607621.077351064,
            "z": 3503536.50449384
        },
        {
            "x": -2678748.047273659,
            "y": 4607621.077351064,
            "z": 3503536.50449384
        },
        {
            "x": -2678748.0481136376,
            "y": 4607621.078795881,
            "z": 3503536.505592448
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.0532869324,
            "y": 4607621.0876942845,
            "z": 3503536.4990368607
        },
        {
            "x": -2678748.0532869324,
            "y": 4607621.0876942845,
            "z": 3503536.4990368607
        },
        {
            "x": -2678748.0541269104,
            "y": 4607621.089139102,
            "z": 3503536.500135469
        },
        {
            "x": -2678748.0541269104,
            "y": 4607621.089139102,
            "z": 3503536.500135469
        },
        {
            "x": -2678748.053706921,
            "y": 4607621.088416693,
            "z": 3503536.499586165
        },
        {
            "x": -2678748.0532869324,
            "y": 4607621.0876942845,
            "z": 3503536.4990368607
        },
        {
            "x": -2678748.0532869324,
            "y": 4607621.0876942845,
            "z": 3503536.4990368607
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.048533627,
            "y": 4607621.07951829,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.048533627,
            "y": 4607621.07951829,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.048533627,
            "y": 4607621.07951829,
            "z": 3503536.506141752
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0520269647,
            "y": 4607621.085527059,
            "z": 3503536.4973889487
        },
        {
            "x": -2678748.0520269647,
            "y": 4607621.085527059,
            "z": 3503536.4973889487
        },
        {
            "x": -2678748.052866943,
            "y": 4607621.086971876,
            "z": 3503536.498487557
        },
        {
            "x": -2678748.052866943,
            "y": 4607621.086971876,
            "z": 3503536.498487557
        },
        {
            "x": -2678748.052866943,
            "y": 4607621.086971876,
            "z": 3503536.498487557
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.052446954,
            "y": 4607621.086249467,
            "z": 3503536.4979382525
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.0489536156,
            "y": 4607621.0802406985,
            "z": 3503536.5066910563
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049793594,
            "y": 4607621.081685516,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0523135285,
            "y": 4607621.086019968,
            "z": 3503536.511085489
        },
        {
            "x": -2678748.0523135285,
            "y": 4607621.086019968,
            "z": 3503536.511085489
        },
        {
            "x": -2678748.0518935397,
            "y": 4607621.085297558,
            "z": 3503536.5105361845
        },
        {
            "x": -2678748.0518935397,
            "y": 4607621.085297558,
            "z": 3503536.5105361845
        },
        {
            "x": -2678748.0518935397,
            "y": 4607621.085297558,
            "z": 3503536.5105361845
        },
        {
            "x": -2678748.0510535613,
            "y": 4607621.083852742,
            "z": 3503536.5094375764
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.0502135833,
            "y": 4607621.082407924,
            "z": 3503536.5083389683
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049793594,
            "y": 4607621.081685516,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.049793594,
            "y": 4607621.081685516,
            "z": 3503536.5077896644
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.049373605,
            "y": 4607621.080963107,
            "z": 3503536.50724036
        },
        {
            "x": -2678748.0481136376,
            "y": 4607621.078795881,
            "z": 3503536.505592448
        },
        {
            "x": -2678764.420122798,
            "y": 4607649.276733472,
            "z": 3503558.1793838153
        },
        {
            "x": -2678764.9350583786,
            "y": 4607650.199471936,
            "z": 3503558.9005480697
        },
        {
            "x": -2678764.9350583786,
            "y": 4607650.199471936,
            "z": 3503558.9005480697
        },
        {
            "x": -2678765.482017623,
            "y": 4607651.140277468,
            "z": 3503559.6025946923
        },
        {
            "x": -2678766.04155689,
            "y": 4607652.102721459,
            "z": 3503560.281129232
        },
        {
            "x": -2678766.04155689,
            "y": 4607652.102721459,
            "z": 3503560.281129232
        },
        {
            "x": -2678766.5999892903,
            "y": 4607653.063261569,
            "z": 3503560.98485975
        },
        {
            "x": -2678766.5999892903,
            "y": 4607653.063261569,
            "z": 3503560.98485975
        },
        {
            "x": -2678767.167947995,
            "y": 4607654.04018754,
            "z": 3503561.714371557
        },
        {
            "x": -2678767.167947995,
            "y": 4607654.04018754,
            "z": 3503561.714371557
        },
        {
            "x": -2678767.718420252,
            "y": 4607654.987035684,
            "z": 3503562.447656502
        },
        {
            "x": -2678767.718420252,
            "y": 4607654.987035684,
            "z": 3503562.447656502
        },
        {
            "x": -2678768.263924006,
            "y": 4607655.943845556,
            "z": 3503563.1716392725
        },
        {
            "x": -2678768.263924006,
            "y": 4607655.943845556,
            "z": 3503563.1716392725
        },
        {
            "x": -2678768.809141212,
            "y": 4607656.900162566,
            "z": 3503563.881925431
        },
        {
            "x": -2678769.3577183303,
            "y": 4607657.862258849,
            "z": 3503564.596606018
        },
        {
            "x": -2678769.3577183303,
            "y": 4607657.862258849,
            "z": 3503564.596606018
        },
        {
            "x": -2678769.8767006714,
            "y": 4607658.791958086,
            "z": 3503565.296419406
        },
        {
            "x": -2678769.8767006714,
            "y": 4607658.791958086,
            "z": 3503565.296419406
        },
        {
            "x": -2678770.3893210804,
            "y": 4607659.7292223,
            "z": 3503565.99843001
        },
        {
            "x": -2678770.9219386014,
            "y": 4607660.663867068,
            "z": 3503566.6922370344
        },
        {
            "x": -2678770.9219386014,
            "y": 4607660.663867068,
            "z": 3503566.6922370344
        },
        {
            "x": -2678771.455396099,
            "y": 4607661.599956656,
            "z": 3503567.3871426643
        },
        {
            "x": -2678771.455396099,
            "y": 4607661.599956656,
            "z": 3503567.3871426643
        },
        {
            "x": -2678772.006760042,
            "y": 4607662.566846506,
            "z": 3503568.0788244205
        },
        {
            "x": -2678772.006760042,
            "y": 4607662.566846506,
            "z": 3503568.0788244205
        },
        {
            "x": -2678772.5431574583,
            "y": 4607663.507992966,
            "z": 3503568.7775751674
        },
        {
            "x": -2678772.5431574583,
            "y": 4607663.507992966,
            "z": 3503568.7775751674
        },
        {
            "x": -2678773.1059428314,
            "y": 4607664.476020518,
            "z": 3503569.5136426124
        },
        {
            "x": -2678773.1059428314,
            "y": 4607664.476020518,
            "z": 3503569.5136426124
        },
        {
            "x": -2678773.654377223,
            "y": 4607665.400855518,
            "z": 3503570.233744267
        },
        {
            "x": -2678773.654377223,
            "y": 4607665.400855518,
            "z": 3503570.233744267
        },
        {
            "x": -2678774.231761988,
            "y": 4607666.338471193,
            "z": 3503570.944030393
        },
        {
            "x": -2678774.231761988,
            "y": 4607666.338471193,
            "z": 3503570.944030393
        },
        {
            "x": -2678774.8065647255,
            "y": 4607667.29015352,
            "z": 3503571.6614574674
        },
        {
            "x": -2678774.8065647255,
            "y": 4607667.29015352,
            "z": 3503571.6614574674
        },
        {
            "x": -2678775.352417088,
            "y": 4607668.229055168,
            "z": 3503572.388700079
        },
        {
            "x": -2678775.888476244,
            "y": 4607669.151111859,
            "z": 3503573.116456035
        },
        {
            "x": -2678775.888476244,
            "y": 4607669.151111859,
            "z": 3503573.116456035
        },
        {
            "x": -2678776.4308973425,
            "y": 4607670.065603565,
            "z": 3503573.842014781
        },
        {
            "x": -2678776.9856315833,
            "y": 4607671.001274673,
            "z": 3503574.5703560165
        },
        {
            "x": -2678776.9856315833,
            "y": 4607671.001274673,
            "z": 3503574.5703560165
        },
        {
            "x": -2678777.5299374466,
            "y": 4607671.937516232,
            "z": 3503575.282254092
        },
        {
            "x": -2678777.5299374466,
            "y": 4607671.937516232,
            "z": 3503575.282254092
        },
        {
            "x": -2678778.081936573,
            "y": 4607672.886990707,
            "z": 3503575.9908923
        },
        {
            "x": -2678778.081936573,
            "y": 4607672.886990707,
            "z": 3503575.9908923
        },
        {
            "x": -2678778.081936573,
            "y": 4607672.886990707,
            "z": 3503575.9908923
        },
        {
            "x": -2678778.615814055,
            "y": 4607673.823802727,
            "z": 3503576.6863472066
        },
        {
            "x": -2678779.1738264915,
            "y": 4607674.783620489,
            "z": 3503577.38952832
        },
        {
            "x": -2678779.1738264915,
            "y": 4607674.783620489,
            "z": 3503577.38952832
        },
        {
            "x": -2678779.7445628396,
            "y": 4607675.728308282,
            "z": 3503578.0883149924
        },
        {
            "x": -2678779.7445628396,
            "y": 4607675.728308282,
            "z": 3503578.0883149924
        },
        {
            "x": -2678779.7445628396,
            "y": 4607675.728308282,
            "z": 3503578.0883149924
        },
        {
            "x": -2678779.7445628396,
            "y": 4607675.728308282,
            "z": 3503578.0883149924
        },
        {
            "x": -2678780.897928723,
            "y": 4607677.638140871,
            "z": 3503579.488121478
        },
        {
            "x": -2678780.897928723,
            "y": 4607677.638140871,
            "z": 3503579.488121478
        },
        {
            "x": -2678781.4857315896,
            "y": 4607678.612184144,
            "z": 3503580.182585584
        },
        {
            "x": -2678781.4857315896,
            "y": 4607678.612184144,
            "z": 3503580.182585584
        },
        {
            "x": -2678782.059479241,
            "y": 4607679.580559487,
            "z": 3503580.882506771
        },
        {
            "x": -2678782.059479241,
            "y": 4607679.580559487,
            "z": 3503580.882506771
        },
        {
            "x": -2678782.6363198804,
            "y": 4607680.554254969,
            "z": 3503581.613117091
        },
        {
            "x": -2678782.6363198804,
            "y": 4607680.554254969,
            "z": 3503581.613117091
        },
        {
            "x": -2678783.1862190943,
            "y": 4607681.500117421,
            "z": 3503582.3190087248
        },
        {
            "x": -2678783.1862190943,
            "y": 4607681.500117421,
            "z": 3503582.3190087248
        },
        {
            "x": -2678783.1862190943,
            "y": 4607681.500117421,
            "z": 3503582.3190087248
        },
        {
            "x": -2678783.745993246,
            "y": 4607682.444457419,
            "z": 3503583.0539415963
        },
        {
            "x": -2678784.267781985,
            "y": 4607683.378984009,
            "z": 3503583.770747436
        },
        {
            "x": -2678784.267781985,
            "y": 4607683.378984009,
            "z": 3503583.770747436
        },
        {
            "x": -2678784.799230465,
            "y": 4607684.330125999,
            "z": 3503584.5001872736
        },
        {
            "x": -2678784.799230465,
            "y": 4607684.330125999,
            "z": 3503584.5001872736
        },
        {
            "x": -2678785.3288655127,
            "y": 4607685.278148783,
            "z": 3503585.240577265
        },
        {
            "x": -2678785.8522720477,
            "y": 4607686.233966139,
            "z": 3503585.970017111
        },
        {
            "x": -2678785.8522720477,
            "y": 4607686.233966139,
            "z": 3503585.970017111
        },
        {
            "x": -2678786.4030112266,
            "y": 4607687.181273418,
            "z": 3503586.677007353
        },
        {
            "x": -2678786.4030112266,
            "y": 4607687.181273418,
            "z": 3503586.677007353
        },
        {
            "x": -2678786.973900696,
            "y": 4607688.126224475,
            "z": 3503587.4026380014
        },
        {
            "x": -2678787.5499543212,
            "y": 4607689.043042176,
            "z": 3503588.113986744
        },
        {
            "x": -2678787.5499543212,
            "y": 4607689.043042176,
            "z": 3503588.113986744
        },
        {
            "x": -2678788.101390835,
            "y": 4607689.9545328505,
            "z": 3503588.8274967805
        },
        {
            "x": -2678788.101390835,
            "y": 4607689.9545328505,
            "z": 3503588.8274967805
        },
        {
            "x": -2678788.552807801,
            "y": 4607690.7124914,
            "z": 3503589.4207092347
        },
        {
            "x": -2678788.552807801,
            "y": 4607690.7124914,
            "z": 3503589.4207092347
        },
        {
            "x": -2678788.893265925,
            "y": 4607691.298101538,
            "z": 3503589.8393507614
        },
        {
            "x": -2678788.893265925,
            "y": 4607691.298101538,
            "z": 3503589.8393507614
        },
        {
            "x": -2678789.148475441,
            "y": 4607691.774094577,
            "z": 3503590.1542097363
        },
        {
            "x": -2678789.148475441,
            "y": 4607691.774094577,
            "z": 3503590.1542097363
        },
        {
            "x": -2678789.3645546422,
            "y": 4607692.164272904,
            "z": 3503590.420694033
        },
        {
            "x": -2678789.3645546422,
            "y": 4607692.164272904,
            "z": 3503590.420694033
        },
        {
            "x": -2678789.5333281183,
            "y": 4607692.473082304,
            "z": 3503590.651951034
        },
        {
            "x": -2678789.5333281183,
            "y": 4607692.473082304,
            "z": 3503590.651951034
        },
        {
            "x": -2678789.658647091,
            "y": 4607692.725655167,
            "z": 3503590.8502138774
        },
        {
            "x": -2678789.658647091,
            "y": 4607692.725655167,
            "z": 3503590.8502138774
        },
        {
            "x": -2678789.7409315547,
            "y": 4607692.922713903,
            "z": 3503591.016031866
        },
        {
            "x": -2678789.8031900125,
            "y": 4607693.085326607,
            "z": 3503591.14233588
        },
        {
            "x": -2678789.8031900125,
            "y": 4607693.085326607,
            "z": 3503591.14233588
        },
        {
            "x": -2678789.8738195053,
            "y": 4607693.225321974,
            "z": 3503591.2319083563
        },
        {
            "x": -2678789.8738195053,
            "y": 4607693.225321974,
            "z": 3503591.2319083563
        },
        {
            "x": -2678789.8738195053,
            "y": 4607693.225321974,
            "z": 3503591.2319083563
        },
        {
            "x": -2678789.9571533995,
            "y": 4607693.350153671,
            "z": 3503591.277094949
        },
        {
            "x": -2678790.024036396,
            "y": 4607693.428180713,
            "z": 3503591.3035692885
        },
        {
            "x": -2678790.024036396,
            "y": 4607693.428180713,
            "z": 3503591.3035692885
        },
        {
            "x": -2678790.08891034,
            "y": 4607693.52126009,
            "z": 3503591.3645779514
        },
        {
            "x": -2678790.08891034,
            "y": 4607693.52126009,
            "z": 3503591.3645779514
        },
        {
            "x": -2678790.0318964026,
            "y": 4607693.608272756,
            "z": 3503591.4484778093
        },
        {
            "x": -2678790.0318964026,
            "y": 4607693.608272756,
            "z": 3503591.4484778093
        },
        {
            "x": -2678789.9645070336,
            "y": 4607693.6404229365,
            "z": 3503591.497771514
        },
        {
            "x": -2678789.9645070336,
            "y": 4607693.6404229365,
            "z": 3503591.497771514
        },
        {
            "x": -2678789.989063244,
            "y": 4607693.590121041,
            "z": 3503591.530586098
        },
        {
            "x": -2678789.989063244,
            "y": 4607693.590121041,
            "z": 3503591.530586098
        },
        {
            "x": -2678790.155238869,
            "y": 4607693.468777163,
            "z": 3503591.5831397115
        },
        {
            "x": -2678790.155238869,
            "y": 4607693.468777163,
            "z": 3503591.5831397115
        },
        {
            "x": -2678790.427014901,
            "y": 4607693.269960377,
            "z": 3503591.653199225
        },
        {
            "x": -2678790.427014901,
            "y": 4607693.269960377,
            "z": 3503591.653199225
        },
        {
            "x": -2678790.7788720573,
            "y": 4607693.005299971,
            "z": 3503591.7522641076
        },
        {
            "x": -2678790.7788720573,
            "y": 4607693.005299971,
            "z": 3503591.7522641076
        },
        {
            "x": -2678791.154178455,
            "y": 4607692.68843365,
            "z": 3503591.9093397264
        },
        {
            "x": -2678791.154178455,
            "y": 4607692.68843365,
            "z": 3503591.9093397264
        },
        {
            "x": -2678791.559500866,
            "y": 4607692.330656696,
            "z": 3503592.1063708775
        },
        {
            "x": -2678791.559500866,
            "y": 4607692.330656696,
            "z": 3503592.1063708775
        },
        {
            "x": -2678791.9406456416,
            "y": 4607691.968308758,
            "z": 3503592.34610408
        },
        {
            "x": -2678791.9406456416,
            "y": 4607691.968308758,
            "z": 3503592.34610408
        },
        {
            "x": -2678792.3076016246,
            "y": 4607691.600063268,
            "z": 3503592.604441785
        },
        {
            "x": -2678792.665451265,
            "y": 4607691.216154343,
            "z": 3503592.8375474084
        },
        {
            "x": -2678792.665451265,
            "y": 4607691.216154343,
            "z": 3503592.8375474084
        },
      
    ]
    
}