<template>
  <div class="chart-container-box ml55 pr cp"  @click="$emit('fn','no2')">
    <span   class="pa cp top20 left-60 tc cf" style="z-index:999">
      <div>NO2</div>(ppm)
    </span>
    <div ref="NO2" class="chart w299 h73"></div>
    <div class="pa right8 cf tc top8">{{ value&&value.toFixed(2) }}</div>
  </div>
</template>
  
<script>
const echarts = require("echarts");

export default {
  name: "NO2",
  data() {
    return {
      chart: null,
      value: 0,
      option: {
        color: ["#fc9f00"],
        grid: {
          top: "40%",
          height: "40%", // 调整 grid 的 height 值，缩小图表的高度
        },
        backgroundColor: "#02173dc4",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          show: false, // 隐藏 Y 轴
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          show: false, // 隐藏 Y 轴
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "NO2",
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            data: [],
          },
        ],
      },
      time:null
    };
  },
  props: {
    airData: {
      type: String | Number,
      default: () => 0,
    },
  },
    beforeDestroy(){
    clearInterval(this.time)
  },
  mounted() {
    this.initChart();
       this.time= setInterval(() => {
      if(this.airData>=0){
        this.updateData( this.airData);
      }
    }, 1000);
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.NO2);
      this.chart.setOption(this.option);
    },
  updateData(value) {
      const now = new Date();
      this.value = value;
      this.option.series[0].data.push([now, value]);
      this.chart.setOption(this.option);
    },
  },
};
</script>
  
<style scoped lang="scss">
.chart-container-box {
  width: 300px;
  border: 1px solid #3bc1e5;

  .chart {
    height: 73px;
  }
}
</style>