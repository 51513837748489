import mqtt from "mqtt";
const {
  VUE_APP_MQTT_URL
} = process.env;
export default {
  initGasDetection(GasDetectionData) {
    let {
      longitude,
      latitude
    } = GasDetectionData
    // 将经纬度转换为 Cartographic 坐标
    const positionCartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
    // 将 Cartographic 坐标转换为 Cartesian3 坐标
    const positionCartesian = Cesium.Cartographic.toCartesian(positionCartographic);
    let data = {
      ...GasDetectionData,
      position: positionCartesian
    }
    this.gasDetectionObj = data
    this.gasDetection.push(data)
  },
  uint8array2json(uint8array) {
    if (!uint8array || !uint8array.length) {
      return uint8array;
    }
    let {
      decoder
    } = this.mqtt;
    if (!decoder) {
      decoder = new TextDecoder("utf-8");
    }
    let content = decoder.decode(uint8array);
    return content ? JSON.parse(content) : content;
  },
  json2uint8array(data) {
    if (!data) {
      return data;
    }
    let {
      encoder
    } = this.mqtt;
    if (!encoder) {
      encoder = new TextEncoder();
    }
    let uint8array = encoder.encode(JSON.stringify(data));
    return uint8array;
  },
  subscribe(topic, callback) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      console.log(topic, 'topic------------');
      client.subscribe(topic, callback);
    }
  },
  unsubscribe(topic) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      client.unsubscribe(topic);
    }
  },
  uuidFn() {
    let random
    let uuid = ''
    for (let i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;  // 解释1
      if (i === 8 || i === 12 || i === 16 || i === 20) { // 解释2
        uuid += '-';
      }
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16); // 解释3
    }
    return uuid;
  },
  initMqtt() {
    try {
      if (this.mqtt.client) {
        this.mqtt.client.end();
        this.mqtt.client = null;
      }
      let url = VUE_APP_MQTT_URL;
      let protocol = url.replace(/(.+)\:\/\/.*/, "$1");
      this.mqtt.client = mqtt.connect(url, {
        protocol,
        clientId: this.uuidFn(),
      });

      this.mqtt.client.on("connect", () => {
        console.log('订阅成功');
      });
      this.mqtt.client.on("reconnect", function (reconnect) { });
      this.mqtt.client.on("`error`", (error) => { });
      this.mqtt.client.on("message", (topic, payload, packet) => {
        let data = this.uint8array2json(payload);
        // let {
        //   latitude,
        //   longitude
        // } = data;
        // // 将经纬度转换为 Cartographic 坐标
        // let positionCartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
        // // 将 Cartographic 坐标转换为 Cartesian3 坐标
        // let positionCartesian = Cesium.Cartographic.toCartesian(positionCartographic);
        // this.positions = positionCartesian
        // // this.customProperty = data
        // // for (let key in data.airData) {
        // //   let item = data.airData[key];
        // //   this.$set(this.airData, key, item)
        // //   // 
        // //   this.calibrationGas(key, item)
        // // }
      });
      let GAStopic = this.GAStopic
      console.log(GAStopic, 'GAStopic');
      // 订阅数据
      this.subscribe(GAStopic)
    } catch (error) {
      console.log(error, 'errorerror');
    }
  },
  calibrationGas(item) {
    let flag = false
    let num = null
    let max = null
    let name = null
    if (item.co > item.coThreshold) {
      name = 'CO'
      num = item.co
      max = item.coThreshold
      flag = true
    } else if (item.no2 > item.no2Threshold) {
      name = 'NO2'
      num = item.no2
      max = item.no2Threshold
      flag = true
    }
    else if (item.ox > item.oxThreshold) {
      name = 'OX'
      num = item.ox
      max = item.oxThreshold
      flag = true
    } else if (item.pm1dot0 > item.pm1dot0Threshold) {
      name = 'PM1.0'
      num = item.pm1dot0
      max = item.pm1dot0Threshold
      flag = true
    } else if (item.pm10 > item.pm10Threshold) {
      name = 'PM10'
      num = item.pm10
      max = item.pm10Threshold
      flag = true
    } else if (item.pm2dot5 > item.pm2dot5Threshold) {
      name = 'PM2.5'
      num = item.pm10
      max = item.pm2dot5Threshold
      flag = true
    } else if (item.so2 > item.so2Threshold) {
      name = 'So2'
      num = item.so2
      max = item.so2Threshold
      flag = true
    }
    if (flag) {
      this.warningInfor = {
        title: "危险提示",
        msg: '检测到气体' + name + '数值为' + num + ',大于' + max + '已对人体造成伤害，请注意防护!'
      }
      this.visible = true
    }
  },

}